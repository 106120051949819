import React, { useState } from "react";
import IconSortDown from "../../../assets/icons/sortDownIcon";
import IconSortUp from "../../../assets/icons/sortUpIcon";


const TableHeader = ({ headers, onSorting }) => {

    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");

    const onSortingChange = (field) => {
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        onSorting(field, order);
    }

    return (
        <thead>
            <tr>
                {headers.map(({ name, field, sortable }) => (
                    <th className="orderable" key={name} onClick={() => sortable ? onSortingChange(field) : null}>
                        {name}
                        {sortingField && sortingField === field && (
                            sortingOrder === "asc" ? <IconSortUp className="icz-tableSortIcon icz-mb-5" /> : <IconSortDown className="icz-tableSortIcon" />
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default TableHeader;