import React from 'react';

import Wrapper from '../../../lib/wrapper';

import DataTable from '../../../components/common/datatable/dataTable';

import '../../../../App.scss';


export default function WebsiteEvents() {

    const events_summary_data = [{"url":"/","landing_page":"google","total_events":87,"unique_events":81},{"url":"/product-category/acmc/","landing_page":"direct","total_events":87,"unique_events":81},{"url":"(not set)","landing_page":"google","total_events":87,"unique_events":81},{"url":"/lodge-a-complaint/","landing_page":"google","total_events":87,"unique_events":81},{"url":"/","landing_page":"(not set)","total_events":87,"unique_events":81}];

    const events_summary_data_headers = [
        { name: "Url", field: "url", sortable: true },
        { name: "Landing Page", field: "landing_page", sortable: true },
        { name: "Total Events", field: "total_events", sortable: true },
        { name: "Unique Events", field: "unique_events", sortable: true }
    ]

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="card fullpage_card grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h3>Summary</h3>
                    </div>
                    <div className="table-content">
                        <div className='table-container'>
                            <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={events_summary_data_headers} TableSize={5} TableData={events_summary_data} />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
