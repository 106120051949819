import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class BarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                data: this.props.ChartData,
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 390
                },

                plotOptions: {
                    bar: {
                        borderRadius: 8,
                        barHeight: "40%",
                        horizontal: this.props.isHorizontal,
                    }
                },

                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: this.props.Legend,
                    showForSingleSeries: false,
                    position: "bottom",
                },

                colors: ['#3CD17F'],

                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },

                xaxis: {
                    categories: this.props.ChartCategory,
                },
            },


        };

    }

    render() {
        return (
            <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="365"
            />
        );
    }
};