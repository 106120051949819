import React, { useState } from "react";

import SocialTwitterPage from './socialTwitterPage';
import SocialTwitterAudience from './socialTwitterAudience';
import SocialTwitterPost from './socialTwitterPost';
import Wrapper from "../../../lib/wrapper";

export const SocialTwitter = () => {
  const [activeTab, setActiveTab] = useState("page");
  const tabs = [
    {
      id: "page",
      name: "Page",
      content: <SocialTwitterPage />,
    },
    {
      id: "audience",
      name: "Audience",
      content: <SocialTwitterAudience />,
    },
    {
      id: "post",
      name: "Post",
      content: <SocialTwitterPost />,
    },
  ];

  return (
    <Wrapper>
      <ul className="tab_navigation">
        {tabs.map((item) => {
          return (
            <li className="tab_navigation_item grid grid-margin-bottom">
              <a
                className={`tab_navigation_link ${activeTab === item.id ? "active" : ""
                  }`}
                href={`#${item.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(item.id);
                }}
              >
                {item.name}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab_content">
        {tabs.map((item) => {
          return (
            <div
              className={`tab_pane ${activeTab === item.id ? "active" : ""}`}
              id={item.id}
            >
              {item.content}
              {/* <Box py={4}>{item.content}</Box> */}
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};