import { useMemo, useState } from 'react';
import '../../../App.scss';
import BarChart from '../../components/common/charts/barChart';
import DonutPieChart from '../../components/common/charts/donutPieChart';
import DataTable from '../../components/common/datatable/dataTable';
import PageHeader from '../../components/common/pageHeader/pageHeader';
import SelectDropdown from '../../components/common/selectDropdown/selectDropdown';

export default function Hashtag() {

  const [isSelected, setSelected] = useState(false);
  const [search, setSearch] = useState("");

  const [reactionTableTitle, setReactionTableTitle] = useState('')
  const [reactionTableData, setReactionTableData] = useState()

  const hashtagList = [{ "hashtag": "DataWarehouse", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "KMMRCE", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "FinTech", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "eComm", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "HeadlessSaaS", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "FutureOfWebsites", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "eCommerce", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "websites", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "ecommercebusiness", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "ecommercemarketing", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "data", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "dataanalysis", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }, { "hashtag": "datawarehousing", "total_posts": 1, "impression": 1325, "engagement": 77, "engagement_ratio": 5.81 }];
  const engagementData = [41, 55, 77];
  const engagementDataLabels = ["Like", "Comment", "Share"];
  const reactionsData = [41, 55, 77, 98, 41, 56];
  const reactionsDataLabels = ["Like", "Love", "Wow", "HAHA", "Sad", "Anger"];
  const locationChartData = [448, 470, 540, 580, 690, 897, 956, 745, 789, 123, 753, 784, 153, 652, 563, 145, 530, 789];
  const locationCategories = ['DataWarehouse', 'KMMRCE', 'FinTech', 'eComm', 'HeadlessSaaS', 'FutureOfWebsites', 'eCommerce', 'websites', 'ecommercebusiness', 'ecommercemarketing', 'data', 'dataanalysis', 'datawarehousing', 'digitalretailing', 'openfinance', 'openbanking', 'financialinnovation', 'financialinclusion'];

  const handleEdit = (badge, index) => {
    setReactionTableTitle(badge.hashtag)

    if(isSelected == ''){
      setSelected(badge.hashtag);
    }
 
    setReactionTableData(badge);

    if(isSelected == badge.hashtag){
      setSelected('');
    } else {
      setSelected(badge.hashtag);
    }

  }


  const badgeDataList = useMemo(() => {
    if (!search) return hashtagList;

    return hashtagList.filter(hashtag => {
      return (
        hashtag.hashtag.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [search, hashtagList]);

  const hashtagPlatformOptions = [
    { value: 'all', label: 'All' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'linkedin', label: 'LinkedIn' }
  ]

  const headers = [
    { name: "Hashtag", field: "hashtag", sortable: true },
    { name: "Total Posts", field: "total_posts", sortable: true },
    { name: "Impression", field: "impression", sortable: true },
    { name: "Engagement", field: "engagement", sortable: true },
    { name: "Engagement Ratio", field: "engagement_ratio", sortable: true },
  ]


  return (
    <main role="main" className="main_page">
      <div className="content" style={{ "width": "100% !important" }}>
        <div id="messages"></div>
        <div className="container_mobile">
          <div className="hide_mobile" style={{ "height": "60px" }}></div>
          <PageHeader PageHeaderTitle={"Hashtag"} />
        </div>

        <div className="page-content">
          <div className="dashboard_home">

            <div className="card fullpage_card grid grid-margin-bottom">
              <div className="table-header flex justify icz_resp_flex_column">
                <h3 className='mb-2'>Trending Hashtags</h3>
                <div className='icz_pageHeaderFiltersSection col-span-2'>
                  <div className='icz_pageHeaderFiltersWrapper icz_selectDropdown icz_sectionDropdown grid grid-cols-4'>
                    <div className='col-span-2'>
                      <div className="form-group mr-2 mb-0" style={{'margin-bottom': '0'}}>
                        <input type="text" name="title" maxLength="255" className="form-control" placeholder="Search Hashtags" id="id_title" value={search} onChange={(e) => setSearch(e.target.value)} />
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <SelectDropdown ismulti={false} placeholder={'Select Platform'} options={hashtagPlatformOptions} className="icz_selectDropdown icz_sectionDropdown" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-content">
                <div className='padded_table_sec'>
                  <div className="group">
                    {
                      badgeDataList == 0 ?
                        <div className="w-100 text-center text-secondary-grey">No Hashtags Found</div>
                        :
                        badgeDataList.map((badge, index) => {
                          return index <= 9 ?
                            <>
                              <button key={index} type="button" className={isSelected == badge.hashtag ? "btn btn-tertiary active" : "btn btn-tertiary"} onClick={() => handleEdit(badge, index)}>{`#` + badge.hashtag}</button>
                            </>
                            : ''
                        })}
                  </div>
                </div>
              </div>
            </div>

            <div className="card fullpage_card grid grid-margin-bottom">
              <div className="table-header flex justify icz_resp_flex_column">
                <h3>Hashtag List</h3>
                {/* <SelectDropdown ismulti={false} placeholder={'Select Platform'} options={hashtagPlatformOptions} className="icz_selectDropdown icz_sectionDropdown" /> */}
              </div>
              <div className="table-content">
                <div className='table-container'>
                  {reactionTableData
                    ? <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={headers} TableSize={5} TableData={isSelected == '' ? badgeDataList : [reactionTableData]} />
                    : <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={headers} TableSize={5} TableData={badgeDataList} />}
                </div>
              </div>
            </div>

            {!isSelected == '' ?
              <div className='card grid grid-margin-bottom'>
                <div className="grid grid-cols-1 ">
                  <div className="table-header icz_resp_flex_column">
                    <h3>{'#' + reactionTableTitle}</h3>
                  </div>
                </div>
                <div className="grid grid-cols-4 ">
                  <div className='col-span-2'>
                    <div className="table-header icz_resp_flex_column">
                      <h3>Total Engagement</h3>
                    </div>
                    <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                      <div className="col-span-3">
                        <div className="line_chart_holder radial_chart_holder padded_table_sec">
                          <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={engagementData} Labels={engagementDataLabels} Legend={true} />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="table-content">
                          <table className="table table-striped table-bordered table-hover">
                            <tbody><tr>
                              <td className="col-md-10">Like</td>
                              <td className="col-md-2 align_right value_td">1.6M</td>
                            </tr>
                              <tr>
                                <td className="col-md-10">Share</td>
                                <td className="col-md-2 align_right value_td">800K</td>
                              </tr>
                              <tr>
                                <td className="col-md-10">Comment</td>
                                <td className="col-md-2 align_right value_td">800K</td>
                              </tr>
                              <tr>
                                <td className="col-md-10"><em>Total Engagement</em></td>
                                <td className="col-md-2 align_right value_td">900K</td>
                              </tr>
                            </tbody></table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-2'>
                    <div className="table-header">
                      <h3>Reaction Sentiments</h3>
                    </div>
                    <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                      <div className="col-span-3">
                        <div className="line_chart_holder radial_chart_holder padded_table_sec">
                          <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={reactionsData} Labels={reactionsDataLabels} Legend={true} />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="table-content">
                          <table className="table table-striped table-bordered table-hover">
                            <tbody><tr>
                              <td className="col-md-10">Like</td>
                              <td className="col-md-2 align_right value_td">1.6M</td>
                            </tr>
                              <tr>
                                <td className="col-md-10">Love</td>
                                <td className="col-md-2 align_right value_td">800K</td>
                              </tr>
                              <tr>
                                <td className="col-md-10">WOW</td>
                                <td className="col-md-2 align_right value_td">800K</td>
                              </tr>
                              <tr>
                                <td className="col-md-10">HAHA</td>
                                <td className="col-md-2 align_right value_td">0</td>
                              </tr>
                              <tr>
                                <td className="col-md-10">Sad</td>
                                <td className="col-md-2 align_right value_td">0</td>
                              </tr>
                              <tr>
                                <td className="col-md-10">Anger</td>
                                <td className="col-md-2 align_right value_td">0</td>
                              </tr>
                            </tbody></table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : ''}

            <div className='card grid grid-cols-4 grid-margin-bottom'>
              <div className="table-header icz_resp_flex_column">
                <h3>Top Hashtags</h3>
                <SelectDropdown ismulti={false} placeholder={'Select Platform'} options={hashtagPlatformOptions} className="icz_selectDropdown icz_sectionDropdown" />
              </div>
              <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                <div className="col-span-4">
                  <div className="line_chart_holder padded_table_sec">
                    <div id="line-chart"></div>
                    <BarChart ChartData={locationChartData} ChartCategory={locationCategories} isHorizontal={false} Legend={true} />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  );
}
