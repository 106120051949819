import { useState } from "react";

import Wrapper from "../../../lib/wrapper";

import CompareTwitterLikes from "./compareTwitterLikes";
import CompareTwitterRetweets from "./compareTwitterRetweet";


export default function CompareTwitter() {

    const [postsubnav, setPostsubnav] = useState("Likes");

    const subnav = ["Likes", "Retweet"];

    const clickHandler = (navtab) => {
        setPostsubnav(navtab)
    }

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className='grid grid-margin-bottom'>
                    <div className="table-header">
                        <div className="group">
                            {subnav.map(navtab => (
                                <button
                                    type="button"
                                    key={navtab}
                                    className={postsubnav === navtab ? "btn btn-tertiary active" : "btn btn-tertiary"}
                                    onClick={() => clickHandler(navtab)}>
                                    {navtab}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <Wrapper >
                    {postsubnav === "Likes" && (
                        <CompareTwitterLikes />
                    )}
                    {postsubnav === "Retweet" && (
                        <CompareTwitterRetweets />
                    )}
                </Wrapper>
            </div>
        </Wrapper>

    )
}