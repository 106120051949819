import React from 'react';

import SelectDropdown from '../../components/common/selectDropdown/selectDropdown';
import Datatable from '../../components/common/datatable/dataTable';

import Wrapper from '../../lib/wrapper';

import LineChart from '../../components/common/charts/lineChart';
import BarChart from '../../components/common/charts/barChart';
import DonutPieChart from '../../components/common/charts/donutPieChart';

import '../../../App.scss';

export default function PerformanceTwitter() {

    const summary_table_data = [{ "campaign_name": "Campaign 1", "platform": "Google", "impression": "773K", "clicks": "3K", "ctr_perc": 0, "spends": "11.3K", "lead_results": 2, "cr": 0, "cpl": "5.6K", "views": 0, "view_rate_perc": 0 }, { "campaign_name": "Campaign 2", "platform": "Facebook", "impression": "773K", "clicks": "3K", "ctr_perc": 0, "spends": "11.3K", "lead_results": 2, "cr": 0, "cpl": "5.6K", "views": 0, "view_rate_perc": 0 }, { "campaign_name": "Campaign 3", "platform": "Instagram", "impression": "773K", "clicks": "3K", "ctr_perc": 0, "spends": "11.3K", "lead_results": 2, "cr": 0, "cpl": "5.6K", "views": 0, "view_rate_perc": 0 }, { "campaign_name": "Campaign 4", "platform": "Twitter", "impression": "773K", "clicks": "3K", "ctr_perc": 0, "spends": "11.3K", "lead_results": 2, "cr": 0, "cpl": "5.6K", "views": 0, "view_rate_perc": 0 }, { "campaign_name": "Campaign 5", "platform": "LinkedIn", "impression": "773K", "clicks": "3K", "ctr_perc": 0, "spends": "11.3K", "lead_results": 2, "cr": 0, "cpl": "5.6K", "views": 0, "view_rate_perc": 0 }];
    const performanceBySourceChartData = [{ "name": "Google", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Facebook", "data": [47, 97, 37, 82, 67, 37, 60, 53] }, { "name": "Instagram", "data": [75, 59, 37, 34, 97, 12, 95, 15] }, { "name": "Twitter", "data": [55, 57, 27, 32, 74, 93, 40, 86] }, { "name": "LinkedIn", "data": [75, 86, 17, 22, 92, 43, 71, 87] }];
    const leadsChartData = [44, 55, 41, 17, 15];
    const leadsChartDataLabels = ["Google Search", "Google Display", "Remarketing"];
    const locationChartData = [400, 430, 448, 470, 540, 580, 690];
    const locationCategories = ['Abu Dhabi', 'Dubai', 'Sharjah', 'Ajman', 'Umm Al Quwain', 'Ras Al Khaimah', 'Fujairah'];

    const locationDropdownOptions = [
        { value: 'abudhabi', label: 'Abu Dhabi' },
        { value: 'dubai', label: 'Dubai' },
        { value: 'sharjah', label: 'Sharjah' },
        { value: 'ajman', label: 'Ajman' },
        { value: 'ummalquwain', label: 'Umm Al Quwain' },
        { value: 'rasalkhaimah', label: 'Ras Al Khaimah' },
        { value: 'fujairah', label: 'Fujairah' }
    ];

    const performanceDropdownOptions = [
        { value: 'impressions', label: 'Impressions' },
        { value: 'clicks', label: 'Clicks' },
        { value: 'spends', label: 'Spends' },
        { value: 'leads', label: 'Leads' },
        { value: 'appinstalls', label: 'App Installs' },
        { value: 'sales', label: 'Sales' },
    ]

    const summary_table_header = [
        { name: "Campaign Name", field: "campaign_name", sortable: true },
        { name: "Platform", field: "platform", sortable: true },
        { name: "Impression", field: "impression", sortable: true },
        { name: "Clicks", field: "clicks", sortable: true },
        { name: "CTR%", field: "ctr_perc", sortable: true },
        { name: "Spends", field: "spends", sortable: true },
        { name: "Lead/Results", field: "lead_results", sortable: true },
        { name: "CR", field: "cr", sortable: true },
        { name: "CPL", field: "cpl", sortable: true },
        { name: "Views", field: "views", sortable: true },
        { name: "View Rate%", field: "view_rate_perc", sortable: true }
    ]

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <div className='grid grid-cols-4'>
                        <div className='grid col-span-2'>
                            <div className="card">
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Leads</h3>
                                </div>
                                <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="col-span-3">
                                        <div className="line_chart_holder padded_table_sec">
                                            <div id="line-chart"></div>
                                            <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={leadsChartData} Labels={leadsChartDataLabels} Legend={true} />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="table-content">
                                            <table className="table table-striped table-bordered table-hover">
                                                <tbody><tr>
                                                    <td className="col-md-10">Google Search</td>
                                                    <td className="col-md-2 align_right value_td">1.6M</td>
                                                </tr>
                                                    <tr>
                                                        <td className="col-md-10">Google Display</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Remarketing</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10"><em>Total Leads</em></td>
                                                        <td className="col-md-2 align_right value_td">0</td>
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid col-span-2'>
                            <div className="card">
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Location</h3>
                                    <SelectDropdown ismulti={false} placeholder={'Select Location'} options={locationDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                                </div>
                                <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="col-span-4">
                                        <div className="line_chart_holder padded_table_sec">
                                            <div id="line-chart"></div>
                                            <BarChart ChartData={locationChartData} ChartCategory={locationCategories} isHorizontal={false} Legend={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='grid grid-cols-4 grid-margin-bottom'>
                        <div className='grid col-span-1'>
                            <div className="card grid">
                                <div className="table-header">
                                    <h3>Device</h3>
                                </div>
                                <div className="table-content">
                                    <table className="table table-striped table-bordered table-hover">
                                        <tbody><tr>
                                            <td className="col-md-10">Desktop</td>
                                            <td className="col-md-2 align_right value_td">10K</td>
                                        </tr>
                                            <tr>
                                                <td className="col-md-10">Tablet</td>
                                                <td className="col-md-2 align_right value_td">2K</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Mobile</td>
                                                <td className="col-md-2 align_right value_td">8.4K</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Other</td>
                                                <td className="col-md-2 align_right value_td">400</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Total</td>
                                                <td className="col-md-2 align_right value_td">10K</td>
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                        </div>
                        <div className='grid col-span-3'>
                            <div className="grid card">
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Performance</h3>
                                    <SelectDropdown ismulti={false} placeholder={'Select'} options={performanceDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                                </div>
                                <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="col-span-3">
                                        <div className="line_chart_holder padded_table_sec">
                                            <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={performanceBySourceChartData} Legend={true} Stroke={true} />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="table-content">
                                            <table className="table table-striped table-bordered table-hover">
                                                <tbody><tr>
                                                    <td className="col-md-10">CTR</td>
                                                    <td className="col-md-2 align_right value_td">10K</td>
                                                </tr>
                                                    <tr>
                                                        <td className="col-md-10">CTC</td>
                                                        <td className="col-md-2 align_right value_td">2K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Conversions</td>
                                                        <td className="col-md-2 align_right value_td">8.4K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">CPL</td>
                                                        <td className="col-md-2 align_right value_td">400</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">CPI</td>
                                                        <td className="col-md-2 align_right value_td">10K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Clicks Conversion</td>
                                                        <td className="col-md-2 align_right value_td">10K</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card fullpage_card">
                        <div className="table-header flex justify">
                            <h3>Summary</h3>
                        </div>
                        <div className="table-content">
                            <div className='table-container'>
                                <Datatable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={summary_table_header} TableSize={5} TableData={summary_table_data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
