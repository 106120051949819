import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Compare from '../module/compare/compare';
import Hashtag from '../module/hashtag/hashtag';

// import Dashboard from '../module/dashboard/dashboard';
import Performance from '../module/performance/performance';
import Social from '../module/social/social';
import Video from '../module/video/video';
import Website from '../module/website/website';


export default function App() {
    return (
        <Switch>
            <Route path="/" exact>
                <Performance />
            </Route>
            <Route path="/performance" exact>
                <Performance />
            </Route>
            <Route path="/social" exact>
                <Social />
            </Route>
            <Route path="/hashtag" exact>
                <Hashtag />
            </Route>
            <Route path="/compare" exact>
                <Compare />
            </Route>
            <Route path="/website" exact>
                <Website />
            </Route>
            <Route path="/video" exact>
                <Video />
            </Route>
        </Switch>
    )
}