import React from 'react';
import PageHeader from '../../components/common/pageHeader/pageHeader';

import PerformanceTabs from './performanceTabs';
import PerformanceOverview from './performanceOverview';
import PerformanceGoogle from './performanceGoogle';
import PerformanceFacebook from './performanceFacebook';
import PerformanceInstagram from './performanceInstagram';
import PerformanceTwitter from './performanceTwitter';
import PerformanceLinkedIn from './performanceLinkedIn';

import '../../../App.scss';

export default function Performance() {

  const tabContent = [
    {
      title: "Summary",
      content: <PerformanceOverview />,
      currentValue: 789,
      growthPercentage: 25
    },
    {
      title: "Google",
      content: <PerformanceGoogle />,
      currentValue: 789,
      growthPercentage: 25
    },
    {
      title: "Facebook",
      content: <PerformanceFacebook />,
      currentValue: 789,
      growthPercentage: 25
    },
    {
      title: "Instagram",
      content: <PerformanceInstagram />,
      currentValue: 789,
      growthPercentage: 25
    },
    {
      title: "Twitter",
      content: <PerformanceTwitter />,
      currentValue: 789,
      growthPercentage: 25
    },
    {
      title: "LinkedIn",
      content: <PerformanceLinkedIn />,
      currentValue: 789,
      growthPercentage: 25
    },
  ];

  return (
    <main role="main" className="main_page">
      <div className="content" style={{ "width": "100% !important" }}>
        <div id="messages"></div>
        <div className="container_mobile">
          <div className="hide_mobile" style={{ "height": "60px" }}></div>
          <PageHeader PageHeaderTitle={"Peformance"} />
        </div>
        <div className="page-content">
          <div className="dashboard_home">
            <PerformanceTabs >
              {tabContent.map((tab, idx) => (
                <PerformanceTabs.TabPane key={idx} tab={tab.title} currentValue={tab.currentValue} growthPercentage={tab.growthPercentage}>
                  {tab.content}
                </PerformanceTabs.TabPane>
              ))}
            </PerformanceTabs>
          </div>
        </div>
      </div>
    </main>
  );
}
