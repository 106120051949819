import React, { useState } from 'react';
import Select from 'react-select';

import './selectDropdown.scss';

export default function SelectDropdown(props) {

    const [selectedOption, setSelectedOption] = useState(null);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            flex: 1,
            autosize:false,
            background: "#fff",
            borderRadius: 7,
            height: 40,
            width: 300,
            borderColor: state.isFocused ? "#acacac" : "#eaeaea",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? "#acacac" : "#acacac"
            }
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 7,
            marginTop: 5,
            zIndex: 999,
            width: 300
        }),

        menuList: (base) => ({
            ...base,
            fontSize: 16
        }),

        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#EBEBEB" : null,
                color: "#26262F"
            };
        }
    };

    return (
        <div className={props.className}>
            <Select
                isMulti={props.ismulti}
                autosize={false}
                backspaceRemovesValue={true}
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={props.options}
                placeholder={props.placeholder}
                isClearable={true}
                hideSelectedOptions={true}
                isSearchable={true}
                styles={customStyles}
                components={{
                    IndicatorSeparator: () => null
                }}
            />
        </div>
    );
}


