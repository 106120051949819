import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';

import Wrapper from '../../../lib/wrapper';

import NumberCounter from '../../../utils/NumberCounter';

import LineChart from '../../../components/common/charts/lineChart';
import SelectDropdown from '../../../components/common/selectDropdown/selectDropdown';
import NegativeValueBarChart from '../../../components/common/charts/negativeValueBarChart';
import DataTable from '../../../components/common/datatable/dataTable';
import BarChart from '../../../components/common/charts/barChart';

import '../../../../App.scss';

export default function SocialTwitterPage() {

    const users_list_data = [{ "name": "Ashlee Hust", "followers": 78, "following": 963, "tweets": 785 }, { "name": "Donald Vilkama", "followers": 978, "following": 493, "tweets": 752 }, { "name": "James Crowell", "followers": 88, "following": 363, "tweets": 785 }, { "name": "James Garcia", "followers": 7777, "following": 3, "tweets": 55 }, { "name": "James Weintraub", "followers": 188, "following": 632, "tweets": 5 }];
    const pageViewsData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }];
    const pageGrowthData = [{ "name": "Like", "data": [0.4, 0.65, 0.76, 0.88, 1.5, 2.1, 2.9, 3.8, 3.9, 4.2, 4, 4.3, 4.1, 4.2, 4.5, 3.9, 3.5, 3] }, { "name": "Unlike", "data": [-0.8, -1.05, -1.06, -1.18, -1.4, -2.2, -2.85, -3.7, -3.96, -4.22, -4.3, -4.4, -4.1, -4, -4.1, -3.4, -3.1, -2.8] }];

    const performanceBySourceChartData = [1765, 836, 1517, 355, 1287, 629, 1263, 946, 1174, 721, 1116, 769, 944, 983, 805, 679];
    const locationCategories = ['kmmrce', 'kmmrcebusiness', 'business', 'digital', 'platform', 'retail', 'retail solutions', 'fintech', 'company', 'demo', 'merchant', 'inventory', 'services', 'growth', 'integration', 'payment gateway' ];

    const pageGrowthDropdownOptions = [
        { value: 'like', label: 'Like' },
        { value: 'followers', label: 'Followers' },
    ]

    const listsDropdownOptions = [
        { value: 'ownership', label: 'Ownership' },
        { value: 'membership', label: 'Membership' }
    ];

    const users_list_data_headers = [
        { name: "Name", field: "name", sortable: true },
        { name: "Followers", field: "followers", sortable: true },
        { name: "Following", field: "following", sortable: false },
        { name: "Tweets", field: "tweets", sortable: false }
    ]

    const mentionsDropdownOptions = [
        { value: 'overall', label: 'Overall' },
        { value: 'positive', label: 'Positive' },
        { value: 'negative', label: 'Negative' },
        { value: 'neutral', label: 'Neutral' }
    ];

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Followers</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Following</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Profile Clicks</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Mentions</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Page Views</h3>
                                <SelectDropdown ismulti={false} placeholder={'Select'} options={pageGrowthDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={pageViewsData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Page Growth</h3>
                                <SelectDropdown ismulti={false} placeholder={'Select'} options={pageGrowthDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <NegativeValueBarChart ChartId={'pageGrowthChart'} isHorizontal={false} Legend={true} ChartData={pageGrowthData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-1 grid-margin-bottom'>
                    <div className="card">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Mentions</h3>
                            <SelectDropdown ismulti={false} placeholder={'Select Mentions'} options={mentionsDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                        </div>
                        <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                            <div className="col-span-4">
                                <div className="line_chart_holder padded_table_sec">
                                    <div id="line-chart"></div>
                                    <BarChart ChartData={performanceBySourceChartData} ChartCategory={locationCategories} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card flex justify">
                        <div className="table-header icz_resp_flex_column">
                            <h3>List</h3>
                            <SelectDropdown ismulti={false} placeholder={'Select'} options={listsDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                        </div>
                        <div className="grid grid-cols-4 table-content">
                            <div className="col-span-3">
                                <div className="line_chart_holder padded_table_sec">
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={users_list_data_headers} TableSize={5} TableData={users_list_data} />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="table-content">
                                    <table className="table table-striped table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <td className="col-md-10">Total No of Lists</td>
                                                <td className="col-md-2 align_right value_td">10K</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Total Followers</td>
                                                <td className="col-md-2 align_right value_td">2K</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Wrapper >
    );
}
