import React, { useState } from "react";
import ProductCard from "./ProductCard";
import Wrapper from "../../../lib/wrapper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';
import Image from 'react-bootstrap/esm/Image';

const ProductComparison = ({ products, Title }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [CheckedItems, setCheckedItems] = useState([]);
    const [disable, setDisable] = React.useState(true);
    const [isCompare, setCompare] = React.useState(false);

    const addToCompare = (item, event) => {
        if (event.target.checked === true) {
            setCheckedItems((CheckedItems) => [...CheckedItems, item]);
            if (CheckedItems.length >= 1) {
                setDisable(false);
            } else {
                setDisable(true);
            }
        }
        else {
            const filteredItems = CheckedItems.filter(
                (product) => product.id !== item.id
            );
            setCheckedItems((CheckedItems) => filteredItems);
            if (CheckedItems.length > 1) {
                setDisable(true);
            } else {
                setDisable(false);

            }
            setCompare(false);
        }
    };

    const MainCompare = () => {
        setSelectedItems(CheckedItems);
        // setCompare(true);
    };


    const removeFromCompare = (item) => {
        let newItems = [];
        selectedItems.map((info, index) => {
            if (info.id !== item.id) {
                newItems.push(info);
            }
        });
        setSelectedItems((selectedItems) => newItems);
        setCheckedItems((CheckedItems) => newItems);
    };

    return (
        <Wrapper>
            <div className="grid grid-cols-1 grid-margin-bottom">
                <div className="table-header flex justify">
                    <h3>{Title}</h3>
                    <button onClick={() => MainCompare()} className="btn btn-primary" disabled={disable}> Compare </button>
                </div>
            </div>
            <div className="grid-cols-1 grid-margin-bottom">
                <div className="grid grid-cols-1 table-content">
                    <div className="grid grid-margin-bottom">
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={3}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                426: {
                                    slidesPerView: 1
                                },
                                768: {
                                    slidesPerView: 1
                                },
                                992: {
                                    slidesPerView: 2
                                },
                                1024: {
                                    slidesPerView: 2
                                },
                                1600: {
                                    slidesPerView: 3
                                }
                            }}>
                            {products.map((product, index) => (
                                <SwiperSlide key={index}>
                                    <ProductCard
                                        key={index}
                                        product={product}
                                        selected={selectedItems}
                                        addToCompare={addToCompare}
                                        removeFromCompare={removeFromCompare}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>

            {selectedItems.length > 0 && (
                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card flex justify">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Compare Results</h3>
                        </div>
                        <div className="grid grid-cols-4 table-content">
                            <div className="col-span-4">
                                <table className="table table-striped table-bordered table-hover">
                                    <tbody>
                                        <tr>
                                            <td className="col-md-10"><em>Video</em></td>
                                            {selectedItems.map((el, index) => (
                                                <td className="col-md-2 align_right value_td" key={index}>
                                                    <Image src={el.image} style={{ 'height': '150px' }} />
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td className="col-md-10"><em>Total Video Views (in minutes)</em></td>
                                            {selectedItems.map((el, index) => (
                                                <td className="col-md-2 align_right value_td" key={index}>{el.reactionvalue}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td className="col-md-10"><em>Avg Time Viewed</em></td>
                                            {selectedItems.map((el, index) => (
                                                <td className="col-md-2 align_right value_td" key={index}>{el.commentsvalue}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td className="col-md-10"><em>Unique viewers</em></td>
                                            {selectedItems.map((el, index) => (
                                                <td className="col-md-2 align_right value_td" key={index}>{el.reachvalue}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td className="col-md-10"><em>View Through Rate</em></td>
                                            {selectedItems.map((el, index) => (
                                                <td className="col-md-2 align_right value_td" key={index}>{el.sharesvalue}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td className="col-md-10"><em>Full Video Views (Organic+Paid)</em></td>
                                            {selectedItems.map((el, index) => (
                                                <td className="col-md-2 align_right value_td" key={index}>{el.engagementrate}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td className="col-md-10"><em>Shares</em></td>
                                            {selectedItems.map((el, index) => (
                                                <td className="col-md-2 align_right value_td" key={index}>{el.clickesvalue}</td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td className="col-md-10"><em>Returning views</em></td>
                                            {selectedItems.map((el, index) => (
                                                <td className="col-md-2 align_right value_td" key={index}>{el.postvalue}</td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Wrapper >
    );


};

export default ProductComparison;
