import React, { useState } from 'react';

import Wrapper from '../../lib/wrapper';

import PageHeader from '../../components/common/pageHeader/pageHeader';



import '../../../App.scss';
import WebsiteOverview from './websiteOverview/overview';
import WebsiteTraffic from './websiteTraffic/websiteTraffic';
import WebsiteBehaviour from './websiteBehaviour/websiteBehaviour';
import WebsiteTechnology from './websiteTechnology/websiteTechnology';
import WebsiteLocation from './websiteLocation/websiteLocation';
import WebsiteLandingPage from './websiteLandingPage/websiteLandingPage';
import WebsiteEvents from './websiteEvents/websiteEvents';
import WebsiteRealTime from './websiteRealTime/websiteRealTime';

const Website = () => {

    const [activeTab, setActiveTab] = useState("Overview");

    const subnav = ["Overview", "Traffic", "Behaviour", "Technology", "Location", "Landing Page", "Events", "Real Time"];

    const clickHandler = (navtab) => {
        setActiveTab(navtab)
    }

    return (
        <Wrapper>
            <main role="main" className="main_page">
                <div className="content" style={{ "width": "100% !important" }}>
                    <div id="messages"></div>
                    <div className="container_mobile">
                        <div className="hide_mobile" style={{ "height": "60px" }}></div>
                        <PageHeader PageHeaderTitle={"Website"} />
                    </div>
                    <div className="page-content">
                        <div className="dashboard_home">
                            <ul className="tab_navigation custom_tab">
                                {subnav.map((navtab, index) => {
                                    return (
                                        <li key={index} className="tab_navigation_item grid grid-margin-bottom">
                                            <a
                                                className={`tab_navigation_link ${activeTab === navtab ? "active" : ""}`}
                                                href={`#${navtab}`} onClick={() => clickHandler(navtab)}>
                                                {navtab}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="tab_content">
                                <Wrapper >
                                    {activeTab === "Overview" && (
                                        <WebsiteOverview />
                                    )}
                                    {activeTab === "Traffic" && (
                                        <WebsiteTraffic />
                                    )}
                                    {activeTab === "Behaviour" && (
                                        <WebsiteBehaviour />
                                    )}
                                    {activeTab === "Technology" && (
                                        <WebsiteTechnology />
                                    )}
                                    {activeTab === "Location" && (
                                        <WebsiteLocation />
                                    )}
                                    {activeTab === "Landing Page" && (
                                        <WebsiteLandingPage />
                                    )}
                                    {activeTab === "Events" && (
                                        <WebsiteEvents />
                                    )}
                                    {activeTab === "Real Time" && (
                                        <WebsiteRealTime />
                                    )}
                                </Wrapper >
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Wrapper >
    );
}

export default Website;
