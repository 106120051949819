import React from 'react'

import Image from 'react-bootstrap/esm/Image';

import Wrapper from '../../../lib/wrapper';

const ProductCard = ({ product, addToCompare, removeFromCompare, selected }) => (
    <Wrapper>
        <div className='card'>
            <div className="grid grid-cols-2 flex  icz_resp_flex_column">
                <div className='col-span-2 padded_table_sec'>
                    <Image src={product.image} style={{ 'width': '100%' }} />
                </div>
                <div className='col-span-2'>
                    <div className="grid table-content">
                        <table className="table table-striped table-bordered table-hover">
                            <tbody>
                                <tr>
                                    <td className="col-md-10">{product.time}</td>
                                    <td className="col-md-2 align_right value_td">
                                        {selected && selected.includes(product) ? (
                                            <input type="checkbox" name="checkbox" className="radio_input" onClick={(event) => removeFromCompare(product)} />
                                        ) : (
                                            <input type="checkbox" name="checkbox" className="radio_input" onClick={(event) => addToCompare(product, event)} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-md-10">Total Views</td>
                                    <td className="col-md-2 align_right value_td">{product.reachvalue}</td>
                                </tr>
                                <tr>
                                    <td className="col-md-10">Avg Time Viewed</td>
                                    <td className="col-md-2 align_right value_td">{product.reachvalue + product.commentsvalue + product.sharesvalue + product.clickesvalue + product.postvalue}</td>
                                </tr>
                                <tr>
                                    <td className="col-md-10">View Through Rate</td>
                                    <td className="col-md-2 align_right value_td">{product.engagementrate}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Wrapper>
);

export default ProductCard;