import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class DonutPieChart extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: this.props.ChartData,
            options: {
                chart: {
                    id: this.props.ChartId,
                    type: this.props.ChartType,
                    height: 390,
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: <svg width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: []
                        },
                        export: {
                            csv: {
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                },
                
                dataLabels: {
                    enabled: false
                },

                labels: this.props.Labels,

                legend: {
                    show: this.props.Legend,
                    showForSingleSeries: true,
                    position: "bottom",
                    fontFamily: "Yellix",
                    color: 'rgb(55, 61, 63)',
                    fontSize: 12,
                    fontWeight: 400
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            show: false,
                        },
                    }
                }]
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                                fontFamily: 'Yellix',
                                fontWeight: 600,
                                color: undefined,
                                offsetY: -10,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Yellix',
                                fontWeight: 400,
                                color: undefined,
                                offsetY: 16,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            total: {
                                show: false,
                                showAlways: false,
                                label: 'Total',
                                fontSize: '22px',
                                fontFamily: 'Yellix',
                                fontWeight: 600,
                                color: '#373d3f',
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0)
                                }
                            }
                            // total: {
                            //     showAlways: true,
                            //     show: true,
                            //     fontFamily: "Yellix",
                            //     color: 'rgb(55, 61, 63)',
                            //     fontSize: 18,
                            //     fontWeight: 400
                            // }
                        }
                    }
                }
            },
        }


    }

    render() {

        let ChartType = this.props.ChartType;

        return (
            <Chart
                options={this.state.options}
                series={this.state.series}
                type={ChartType}
                height="300"
            />
        );
    }
};