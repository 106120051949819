import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';

import Wrapper from '../../../lib/wrapper';

import NumberCounter from '../../../utils/NumberCounter';

import LineChart from '../../../components/common/charts/lineChart';
import MultipleYAxisChart from '../../../components/common/charts/multipleYAxisChart';
import HeatMapChart from '../../../components/common/charts/heatMapChart';
import BubbleChart from '../../../components/common/charts/bubbleChart';
import SelectDropdown from '../../../components/common/selectDropdown/selectDropdown';

import '../../../../App.scss';


export default function SocialTwitterPostOrganic() {

    const fbPostOverallPostEngagementData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }, { "name": "Instagram", "data": [75, 59, 37, 34, 97, 12, 95, 15] }, { "name": "Twitter", "data": [55, 57, 27, 32, 74, 93, 40, 86] }, { "name": "LinkedIn", "data": [75, 86, 17, 22, 92, 43, 71, 87] }];

    const fbPostOverallPostImpressionData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }];

    const ttPostOverallVideoViewsData = [{ "name": "No of Videos", "type": "column", "data": [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6] }, { "name": "Video Views", "type": "line", "data": [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5] }];
    const ttPostOverallVideoViewsDataCategories = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]

    const ttPostOverallPollCountData = [{ "name": "No of Polls", "type": "column", "data": [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6] }, { "name": "Vote Count", "type": "line", "data": [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5] }];
    const ttPostOverallPollCountDataCategories = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]

    const ttPostOverallCampaignEffectivenessData = [{ "name": "No of Visits", "type": "column", "data": [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6] }, { "name": "No of Views", "type": "line", "data": [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5] }];
    const ttPostOverallCampaignEffectivenessDataCategories = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]

    const tweetCountData = [{ "name": "Mon", "data": [73, 68, 10, 24, 43, 59, 75, 65, 11, 76, 27, 56, 40, 84, 81, 86, 9, 35, 31, 7, 62, 38, 59, 73] }, { "name": "Tue", "data": [11, 4, 61, 87, 81, 40, 44, 76, 74, 53, 77, 68, 19, 30, 87, 54, 59, 88, 16, 47, 11, 77, 40, 14,] }, { "name": "Wed", "data": [59, 4, 21, 7, 3, 43, 59, 67, 51, 33, 48, 82, 21, 84, 2, 75, 44, 80, 67, 24, 48, 44, 4, 6,] }, { "name": "Thu", "data": [31, 81, 56, 46, 46, 13, 35, 16, 84, 83, 54, 14, 33, 33, 73, 82, 53, 23, 27, 78, 84, 27, 47, 76] }, { "name": "Fri", "data": [47, 53, 71, 50, 85, 15, 86, 56, 70, 40, 18, 90, 76, 49, 69, 70, 46, 42, 66, 50, 32, 29, 21, 43] }, { "name": "Sat", "data": [10, 59, 89, 46, 11, 57, 4, 35, 26, 56, 21, 52, 44, 46, 26, 77, 66, 70, 73, 63, 69, 44, 84, 24] }, { "name": "Sun", "data": [64, 65, 71, 8, 10, 41, 44, 21, 77, 12, 38, 7, 64, 65, 71, 8, 10, 41, 44, 21, 10, 41, 44, 21] }];

    const engagementChartData = [{ "name": "Tweet", "data": [[230, 11, 130]] }, { "name": "Video", "data": [[259, 48, 120]] }, { "name": "Media", "data": [[299, 23, 110]] }, { "name": "Poll", "data": [[259, 30, 100]] }];

    const engagementDropdownOptions = [
        { value: 'impressions', label: 'Impressions' },
        { value: 'tweetlength', label: 'Tweet Length' }
    ]

    const tweetsCountDropdownOptions = [
        { value: 'organic', label: 'Organic' },
        { value: 'promoted', label: 'Promoted' },
        { value: 'total', label: 'Total' }
    ]

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Tweets</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Engagement</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Impressions</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Profile Clicks</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Engagement Rate</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Video Views</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Url Links Click</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className='grid grid-cols-1'>
                        <div className="card flex justify">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Tweet Impressions</h3>
                            </div>
                            <div className="grid grid-cols-1 table-content">
                                <div className="line_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={fbPostOverallPostImpressionData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Video Views</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <MultipleYAxisChart
                                        ChartId={'fbPostOverallVideoViews'}
                                        Legend={true}
                                        ChartData={ttPostOverallVideoViewsData}
                                        ChartDataCategories={ttPostOverallVideoViewsDataCategories} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Poll Votes</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <MultipleYAxisChart
                                        ChartId={'fbPostOverallVideoViews'}
                                        Legend={true}
                                        ChartData={ttPostOverallPollCountData}
                                        ChartDataCategories={ttPostOverallPollCountDataCategories} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card flex justify">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Tweet Engagement</h3>
                        </div>
                        <div className="grid grid-cols-4 table-content">
                            <div className="col-span-3">
                                <div className="line_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={fbPostOverallPostEngagementData} Legend={true} Stroke={true} />

                                </div>
                            </div>
                            <div className="col-span-1 flex center">
                                <div className="table-content">
                                    <table className="table table-striped table-bordered table-hover">
                                        <tbody><tr>
                                            <td className="col-md-10">Retweets</td>
                                            <td className="col-md-2 align_right value_td">10K</td>
                                        </tr>
                                            <tr>
                                                <td className="col-md-10">Quote Tweets</td>
                                                <td className="col-md-2 align_right value_td">2K</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Likes</td>
                                                <td className="col-md-2 align_right value_td">8.4K</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Replies</td>
                                                <td className="col-md-2 align_right value_td">400</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Clicks</td>
                                                <td className="col-md-2 align_right value_td">400</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10"><em>Total Engagement</em></td>
                                                <td className="col-md-2 align_right value_td"><em>100K</em></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className='grid grid-cols-1'>
                        <div className="card flex justify">
                            <div className="table-header icz_resp_flex_column">
                                <h3>User Profile Clicks</h3>
                            </div>
                            <div className="grid grid-cols-1 table-content">
                                <div className="line_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={fbPostOverallPostImpressionData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Wrapper >
    );
}
