import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';
import Image from 'react-bootstrap/Image';


import Wrapper from '../../../lib/wrapper';

import LineChart from '../../../components/common/charts/lineChart';
import MultipleYAxisChart from '../../../components/common/charts/multipleYAxisChart';
import HalfDonutPieChart from '../../../components/common/charts/halfDonutPieChart';
import StackedColumnsChart from '../../../components/common/charts/stackedColumnsChart';

import NumberCounter from '../../../utils/NumberCounter';

import PostImg from '../../../assets/images/post1.png'

import '../../../../App.scss';
import ProductComparison from '../../../components/common/compare/ProductComparision';
import {products} from '../../../components/common/compare/Products';

export default function SocialInstagramPostOrganic() {

    const fbPostOrganicContentAnalysisData = [{ "name": "Post Clicks", "data": [44, 13, 26, 78] }, { "name": "Reactions", "data": [13, 23, 74, 45] }, { "name": "Comments", "data": [45, 79, 13, 23] }, { "name": "Shares", "data": [11, 34, 28, 33] }];
    const fbPostOrganicContentAnalysisDataCategory = ['Video', 'Album', 'Static', 'Link'];

    const fbPostOrganicPostEngagementData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }, { "name": "Instagram", "data": [75, 59, 37, 34, 97, 12, 95, 15] }, { "name": "Twitter", "data": [55, 57, 27, 32, 74, 93, 40, 86] }, { "name": "LinkedIn", "data": [75, 86, 17, 22, 92, 43, 71, 87] }];

    const fbPostOrganicPostImpressionData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }];

    const fbPostOrganicVideoViewsData = [{ "name": "No of Videos", "type": "column", "data": [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6] }, { "name": "Video Views", "type": "line", "data": [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5] }];
    const fbPostOrganicVideoViewsDataCategories = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]

    const fbPostOrganicReachByContentTypeData = [44, 55, 41, 17];
    const fbPostOrganicReachByContentTypeDataCategories = ['Video', 'Album', 'Static', 'Link'];

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Posts</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Likes</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Post Impressions</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Post Reach</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Engagement Rate</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Shares</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Content Analysis</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <StackedColumnsChart ChartId={"currentPeriodLikeUnlike"} ChartData={fbPostOrganicContentAnalysisData} ChartCategories={fbPostOrganicContentAnalysisDataCategory} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Reach by Content Type</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    {/* <NegativeValueBarChart ChartId={'pageGrowthChart'} isHorizontal={false} Legend={true} ChartData={pageGrowthData} /> */}
                                    <HalfDonutPieChart ChartId={'fbPostOverallPostReachByContentType'} ChartData={fbPostOrganicReachByContentTypeData} ChartDataCategories={fbPostOrganicReachByContentTypeDataCategories} Legend={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card flex justify">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Post Impressions</h3>
                        </div>
                        <div className="grid grid-cols-4 table-content">
                            <div className="col-span-2">
                                <div className="line_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={fbPostOrganicPostImpressionData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                            <div className="col-span-2 flex center align table-content">
                                <div className="grid grid-cols-4 table-content">
                                    <div className='col-span-2 padded_table_sec'>
                                        <Image src={PostImg} style={{ 'width': '100%' }} />
                                    </div>
                                    <div className='col-span-2'>
                                        <div className="grid table-content">
                                            <table className="table table-striped table-bordered table-hover">
                                                <tbody><tr>
                                                    <td className="col-md-10">Total Impressions</td>
                                                    <td className="col-md-2 align_right value_td">10K</td>
                                                </tr>
                                                    <tr>
                                                        <td className="col-md-10">Total Reactions</td>
                                                        <td className="col-md-2 align_right value_td">2K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Today's Contribution</td>
                                                        <td className="col-md-2 align_right value_td">8.4K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Today's Impression</td>
                                                        <td className="col-md-2 align_right value_td">400</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-12">
                                                            <button type="button" className="btn btn-tertiary" disabled>Geo-targeted</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card flex justify">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Video Views</h3>
                        </div>
                        <div className="grid grid-cols-1 table-content">
                            <div className="line_chart_holder padded_table_sec">
                                <MultipleYAxisChart
                                    ChartId={'fbPostOverallVideoViews'}
                                    Legend={true}
                                    ChartData={fbPostOrganicVideoViewsData}
                                    ChartDataCategories={fbPostOrganicVideoViewsDataCategories} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card flex justify">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Post Engagement</h3>
                        </div>
                        <div className="grid grid-cols-4 table-content">
                            <div className="col-span-3">
                                <div className="line_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={fbPostOrganicPostEngagementData} Legend={true} Stroke={true} />

                                </div>
                            </div>
                            <div className="col-span-1 flex center">
                                <div className="table-content">
                                    <table className="table table-striped table-bordered table-hover">
                                        <tbody><tr>
                                            <td className="col-md-10">Like</td>
                                            <td className="col-md-2 align_right value_td">10K</td>
                                        </tr>
                                            <tr>
                                                <td className="col-md-10">Comments</td>
                                                <td className="col-md-2 align_right value_td">2K</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Shares</td>
                                                <td className="col-md-2 align_right value_td">8.4K</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10">Post Link Clicks</td>
                                                <td className="col-md-2 align_right value_td">400</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-10"><em>Total Impressions</em></td>
                                                <td className="col-md-2 align_right value_td"><em>100K</em></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ProductComparison products={products} Title={'Top 5 Posts'}/>
            </div>
        </Wrapper >
    );
}
