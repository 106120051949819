import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class NegativeValueBarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: this.props.ChartData,
            options: {
                chart: {
                    id: this.props.ChartId,
                    stacked: true,
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: <svg width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: []
                        },
                        export: {
                            csv: {
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: this.props.isHorizontal,
                        // barHeight: '80%',
                    },
                },
                dataLabels: {
                    enabled: false
                },

                stroke: {
                    show: this.props.Stroke,
                    curve: 'smooth',
                    lineCap: 'butt',
                    width: 2,
                    dashArray: 0,
                },

                legend: {
                    show: this.props.Legend,
                    showForSingleSeries: true,
                    position: "top",
                    fontFamily: "Yellix",
                    color: 'rgb(55, 61, 63)',
                    fontSize: 12,
                    fontWeight: 400
                },

                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },

                tooltip: {
                    shared: false,
                    x: {
                        formatter: function (val) {
                            return val
                        }
                    },
                    y: {
                        formatter: function (val) {
                            return Math.abs(val) + "%"
                        }
                    }
                },

                xaxis: {
                    categories: ['1 Jan', '2 Jan', '3 Jan', '4 Jan', '5 Jan', '6 Jan', '7 Jan', '8 Jan',
                        '9 Jan', '10 Jan', '11 Jan', '12 Jan', '13 Jan', '14 Jan', '15 Jan', '16 Jan', '17 Jan',
                        '18 Jan'
                    ],
                    labels: {
                        formatter: function (val) {
                            return Math.abs(Math.round(val)) + "%"
                        },
                        style: {
                            fontFamily: "Yellix",
                            color: 'rgb(55, 61, 63)',
                            fontSize: 11,
                            fontWeight: 400
                        },
                    }
                },
                yaxis: {
                    min: -5,
                    max: 5,
                    labels: {
                        show: true,
                        style: {
                            fontFamily: "Yellix",
                            color: 'rgb(55, 61, 63)',
                            fontSize: 11,
                            fontWeight: 400
                        },
                    },
                }
            },


        };
    }

    render() {

        return (
            <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="365"
            />
        );
    }
};