import React from 'react';

import Wrapper from '../../../lib/wrapper';

import NumberCounter from '../../../utils/NumberCounter';
import DataTable from '../../../components/common/datatable/dataTable';

import '../../../../App.scss';


export default function WebsiteRealTime() {

    const activePageData = [{ "active_page": "/", "active_users": 80 }, { "active_page": "/men.html", "active_users": 70 }, { "active_page": "/aboutus.html", "active_users": 75 }, { "active_page": "/checkout.html", "active_users": 79 }, { "active_page": "/checkout.html", "active_users": 40 }];
    const activePageDataHeaders = [
        { name: "Active Page", field: "active_page", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]    
    
    const countryData = [{ "country": "UAE", "active_users": 80 }, { "country": "India", "active_users": 70 }, { "country": "USA", "active_users": 75 }, { "country": "UK", "active_users": 79 }, { "country": "Canada", "active_users": 40 }];
    const countryDataHeaders = [
        { name: "Country", field: "country", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]

    const regionData = [{ "region": "Abu Dhabi", "active_users": 80 }, { "region": "Dubai", "active_users": 70 }, { "region": "Sharjah", "active_users": 75 }, { "region": "Ajman", "active_users": 79 }, { "region": "Umm Al-Quwain", "active_users": 40 }];
    const regionDataHeaders = [
        { name: "Region", field: "metricname", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]

    const cityData = [{ "city": "Dubai", "active_users": 80 }, { "city": "Abu Dhabi", "active_users": 70 }, { "city": "Ajman", "active_users": 75 }, { "city": "Al Sharjah", "active_users": 79 }, { "city": "Al Ain", "active_users": 40 }];
    const cityDataHeaders = [
        { name: "City", field: "city", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]

    const browserData = [{ "browser": "Chrome", "active_users": 80 }, { "browser": "Firefox", "active_users": 70 }, { "browser": "Andorid Webview", "active_users": 75 }, { "browser": "Safari", "active_users": 79 }, { "browser": "Safari (in-app)", "active_users": 40 }];
    const browserDataHeaders = [
        { name: "Browser", field: "browser", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]

    const osData = [{ "operation_system": "Android", "active_users": 80 }, { "operation_system": "Windows", "active_users": 70 }, { "operation_system": "iOS", "active_users": 75 }, { "operation_system": "Linux", "active_users": 79 }, { "operation_system": "Others", "active_users": 40 }];
    const osDataHeaders = [
        { name: "Operation System", field: "operation_system", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]

    const deviceCategoryData = [{ "device_category": "Mobile", "active_users": 80 }, { "device_category": "Desktop", "active_users": 70 }, { "device_category": "Tablet", "active_users": 75 }];
    const deviceCategoryDataHeaders = [
        { name: "Device Category", field: "device_category", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]

    const trafficMediumData = [{ "medium": "(none)", "active_users": 80 }, { "medium": "social", "active_users": 70 }, { "medium": "CPP", "active_users": 75 }, { "medium": "CPC", "active_users": 79 }, { "medium": "CPV", "active_users": 40 }];
    const trafficMediumDataHeaders = [
        { name: "Medium", field: "medium", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]

    const trafficSourceData = [{ "source": "Facebook", "active_users": 80 }, { "source": "(direct)", "active_users": 70 }, { "source": "google", "active_users": 75 }, { "source": "ads display", "active_users": 79 }, { "source": "affiliate", "active_users": 40 }];
    const trafficSourceDataHeaders = [
        { name: "Source", field: "source", sortable: true },
        { name: "Active Users", field: "active_users", sortable: true }
    ]

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-cols-4 grid-margin-bottom">
                    <div className="card card_padded cursor-pointer">
                        <div className="statbox">
                            <h4>Active users on site</h4>
                            <h3><span className="animate_number_decimal"><NumberCounter Number={89} /></span></h3>
                            <div className="statbox_info show">
                                <span className="grey_text block">Right Now</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-4  grid-margin-bottom">
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>Active Page</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={activePageDataHeaders} TableSize={5} TableData={activePageData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>Country</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={countryDataHeaders} TableSize={5} TableData={countryData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>Region</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={regionDataHeaders} TableSize={5} TableData={regionData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>City</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={cityDataHeaders} TableSize={5} TableData={cityData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>Browser</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={browserDataHeaders} TableSize={5} TableData={browserData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>Operating System</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={osDataHeaders} TableSize={5} TableData={osData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>Device Category</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={deviceCategoryDataHeaders} TableSize={5} TableData={deviceCategoryData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>Traffic Medium</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={trafficMediumDataHeaders} TableSize={5} TableData={trafficMediumData} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="card cursor-pointer">
                            <div className="table-header flex justify">
                                <h3>Traffic Source</h3>
                            </div>
                            <div className="table-content">
                                <div className='table-container'>
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={trafficSourceDataHeaders} TableSize={5} TableData={trafficSourceData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
