import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';
import Wrapper from "../../../lib/wrapper";


const VideoYouTubeTabs = ({children, active = 0 }) => {
    const [activeTab, setActiveTab] = useState(active);
    const [tabsData, setTabsData] = useState([]);

    let isSelectedTab = 0;

    const toggleTab = (idx) => {
        if (isSelectedTab === idx) {
            isSelectedTab = 0;
        } else {
            isSelectedTab = idx;
        }
        setActiveTab(isSelectedTab);
    }

    useEffect(() => {
        let data = [];

        React.Children.forEach(children, (element) => {
            if (!React.isValidElement(element)) return;

            const {
                props: { tab, currentValue, growthPercentage, children },
            } = element;
            data.push({ tab, currentValue, growthPercentage, children });
        });

        setTabsData(data);
    }, [children]);


    return (
        <Wrapper>
            <div className="grid grid-margin-bottom">
                <Swiper
                    modules={[Pagination]}
                    spaceBetween={20}
                    slidesPerView={3}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        0: {
                            slidesPerView: 'auto'
                        },
                        426: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 3
                        },
                        1024: {
                            slidesPerView: 3
                        },
                        1600: {
                            slidesPerView: 4
                        }
                    }}>
                    {tabsData.map((tab, idx) => (
                        <SwiperSlide style={idx === 0 ? { display: 'none' } : { display: 'block' }} key={idx} role="button" className={`${idx === activeTab ? "active" : ""}`} onClick={() => toggleTab(idx)}>
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>{tab.tab}</h4>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {tabsData[activeTab] && tabsData[activeTab].children}
        </Wrapper>
    );
};

const TabPane = ({ children }) => {
    return { children };
};

VideoYouTubeTabs.TabPane = TabPane;

export default VideoYouTubeTabs;
