import React, { useState } from "react";

import SocialInstagramPage from "./socialInstagramPage";
import SocialInstagramAudience from './socialInstagramAudience';
import SocialInstagramPost from './socialInstagramPost';

const SocialInstagram = () => {
  const [activeTab, setActiveTab] = useState("page");
  const tabs = [
    {
      id: "page",
      name: "Page",
      content: <SocialInstagramPage />,
    },
    {
      id: "audience",
      name: "Audience",
      content: <SocialInstagramAudience />,
    },
    {
      id: "post",
      name: "Post",
      content: <SocialInstagramPost />,
    },
  ];
  
  return (
    <>
      <ul className="tab_navigation">
        {tabs.map((item, index) => {
          return (
            <li key={index} className="tab_navigation_item grid grid-margin-bottom">
              <a
                className={`tab_navigation_link ${
                  activeTab === item.id ? "active" : ""
                }`}
                href={`#${item.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(item.id);
                }}
              >
                {item.name}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab_content">
      {tabs.map((item, index) => {
          return (
            <div key={index} className={`tab_pane ${activeTab === item.id ? "active" : ""}`} id={item.id} >
              {item.content}
          </div>
          );
      })}
      </div>
    </>
  );
};
export default SocialInstagram;