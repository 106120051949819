import React from 'react';

import VideoYouTubeTabs from './videoYouTubeTabs';

import VideoYouTubePageAnalytics from './videoYouTubePageAnalytics';
import VideoYouTubeVideoAnalytics from './videoYouTubeVideoAnalytics';

import '../../../../App.scss';


export default function VideoYouTube() {

    const tabContent = [
        {
            title: "Overall",
            content: <VideoYouTubePageAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "YouTube Video 1",
            content: <VideoYouTubeVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "YouTube Video 2",
            content: <VideoYouTubeVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "YouTube Video 3",
            content: <VideoYouTubeVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "YouTube Video 4",
            content: <VideoYouTubeVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "YouTube Video 5",
            content: <VideoYouTubeVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
    ];

    return (
        <div className="dashboard_home">
            <VideoYouTubeTabs >
                {tabContent.map((tab, idx) => (
                    <VideoYouTubeTabs.TabPane key={idx} tab={tab.title} currentValue={tab.currentValue} growthPercentage={tab.growthPercentage}>
                        {tab.content}
                    </VideoYouTubeTabs.TabPane>
                ))}
            </VideoYouTubeTabs>
        </div>
    );
}
