import Post1 from '../../../assets/images/post1.png';
import Post2 from '../../../assets/images/post2.jpg';
import Post3 from '../../../assets/images/post3.jpg';
import Post4 from '../../../assets/images/post4.jpg';
import Post5 from '../../../assets/images/post5.jpg';

export const products = [
  {
    id: "1",
    name: "Post 1",
    image: Post1,
    time: "10:00 AM | 01 Jan 2021",
    reactionvalue: 100,
    reactions: "Reactions",
    comments: "Comments",
    commentsvalue: 120,
    reach: "Organic Reach",
    reachvalue: 50,
    shares: "Shares",
    sharesvalue: 80,
    rate: "Engagement Rate",
    engagementrate: 110,
    clicks: "Link Clicks",
    clickesvalue: 200,
    post: "Post Clicks",
    postvalue: 135,
    link: "View Post"
  },
  {
    id: "2",
    name: "Post 2",
    image: Post2,
    time: "10:00 AM | 01 Jan 2021",
    reactionvalue: 100,
    reactions: "Reactions",
    comments: "Comments",
    commentsvalue: 120,
    reach: "Organic Reach",
    reachvalue: 50,
    shares: "Shares",
    sharesvalue: 80,
    rate: "Engagement Rate",
    engagementrate: 110,
    clicks: "Link Clicks",
    clickesvalue: 200,
    post: "Post Clicks",
    postvalue: 135,
    link: "View Post"
  },
  {
    id: "3",
    name: "Post 3",
    image: Post3,
    time: "10:00 AM | 01 Jan 2021",
    reactionvalue: 100,
    reactions: "Reactions",
    comments: "Comments",
    commentsvalue: 120,
    reach: "Organic Reach",
    reachvalue: 50,
    shares: "Shares",
    sharesvalue: 80,
    rate: "Engagement Rate",
    engagementrate: 110,
    clicks: "Link Clicks",
    clickesvalue: 200,    
    post: "Post Clicks",
    postvalue: 135,
    link: "View Post"
  },
  {
    id: "4",
    name: "Post 4",
    image: Post4,
    time: "10:00 AM | 01 Jan 2021",
    reactionvalue: 100,
    reactions: "Reactions",
    comments: "Comments",
    commentsvalue: 120,
    reach: "Organic Reach",
    reachvalue: 50,
    shares: "Shares",
    sharesvalue: 80,
    rate: "Engagement Rate",
    engagementrate: 110,
    clicks: "Link Clicks",
    clickesvalue: 200,
    post: "Post Clicks",
    postvalue: 135,
    link: "View Post"
  },
  {
    id: "5",
    name: "Post 5",
    image: Post5,
    time: "10:00 AM | 01 Jan 2021",
    reactionvalue: 100,
    reactions: "Reactions",
    comments: "Comments",
    commentsvalue: 120,
    reach: "Organic Reach",
    reachvalue: 50,
    shares: "Shares",
    sharesvalue: 80,
    rate: "Engagement Rate",
    engagementrate: 110,
    clicks: "Link Clicks",
    clickesvalue: 200,
    post: "Post Clicks",
    postvalue: 135,
    link: "View Post"
  }
];
