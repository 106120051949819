import React, { useState } from 'react';

import SelectDropdown from '../selectDropdown/selectDropdown';

import IconCalendar from '../../../assets/icons/calendarIcon';

import './pageHeader.scss';


const campaignDropdownOptions = [
    { value: 'newyearcampaign', label: 'New Year Campaign' },
    { value: 'christmascampaign', label: 'Christmas Campaign' },
    { value: 'diwalicampaign', label: 'Diwali Campaign' },
    { value: 'navratricampaign', label: 'Navratri Campaign' },
    { value: 'blackfridaycampaign', label: 'Black Friday Campaign' },
    { value: 'wintercampaign', label: 'wintercampaign' },
];




export default function PageHeader(props) {

    const [showDatePicker, setshowDatePicker] = useState(false);


    return (
        <div className="page-header grid grid-cols-4">
            <h1 className='col-span-2'>
                {props.PageHeaderTitle}
            </h1>
            <div className='icz_pageHeaderFiltersSection col-span-2'>
                <div className='icz_pageHeaderFiltersWrapper grid grid-cols-4'>
                    <div className='col-span-2'>
                        <SelectDropdown ismulti={true} placeholder={'Choose Campaign'} options={campaignDropdownOptions} />
                    </div>
                    <div className='col-span-2'>
                        <div className='icz_datePickerContainer'>
                            <div onClick={() => setshowDatePicker(!showDatePicker)} className="icz_datePickerSection">
                                <div className="icz_datePickerWrapper">
                                    <div className="icz_date icz_fromDate">
                                        15 Jan 2021
                                    </div>
                                    <div className="text-secondary-grey">to</div>
                                    <div className="icz_date icz_toDate">
                                        15 Feb 2021
                                    </div>
                                    <IconCalendar className="icz_datePickerCalendar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}