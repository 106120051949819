import React from 'react';

import Wrapper from '../../../lib/wrapper';

import DataTable from '../../../components/common/datatable/dataTable';

import '../../../../App.scss';


export default function WebsiteLandingPage() {

    const landing_page_summary_data = [{"url":"/","sessions":31,"new_sessions":31,"new_user":70.97,"bounce_rate":70.97,"pages_session":1.84,"avg_session_duration":0},{"url":"/login/update_password","sessions":31,"new_sessions":31,"new_user":70.97,"bounce_rate":70.97,"pages_session":1.84,"avg_session_duration":0},{"url":"/registration","sessions":31,"new_sessions":31,"new_user":70.97,"bounce_rate":70.97,"pages_session":1.84,"avg_session_duration":0},{"url":"/contact_us","sessions":31,"new_sessions":31,"new_user":70.97,"bounce_rate":70.97,"pages_session":1.84,"avg_session_duration":0},{"url":"/events/events_list/2022/January","sessions":31,"new_sessions":31,"new_user":70.97,"bounce_rate":70.97,"pages_session":1.84,"avg_session_duration":0}];

    const landing_page_summary_data_headers = [
        { name: "Url", field: "url", sortable: true },
        { name: "Sessions", field: "sessions", sortable: true },
        { name: "New Sessions", field: "new_sessions", sortable: true },
        { name: "New User", field: "new_user", sortable: true },
        { name: "Bounce Rate", field: "bounce_rate", sortable: true },
        { name: "Pages/Session", field: "pages_session", sortable: true },
        { name: "Avg. Session Duration", field: "avg_session_duration", sortable: true },
    ]

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="card fullpage_card grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h3>Summary</h3>
                    </div>
                    <div className="table-content">
                        <div className='table-container'>
                            <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={landing_page_summary_data_headers} TableSize={5} TableData={landing_page_summary_data} />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
