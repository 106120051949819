import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';

import Wrapper from '../../../lib/wrapper';

import NumberCounter from '../../../utils/NumberCounter';

import LineChart from '../../../components/common/charts/lineChart';
import MultipleYAxisChart from '../../../components/common/charts/multipleYAxisChart';

import '../../../../App.scss';
import Col from 'react-bootstrap/esm/Col';
import DonutPieChart from '../../../components/common/charts/donutPieChart';
import HalfDonutPieChart from '../../../components/common/charts/halfDonutPieChart';
import BarChart from '../../../components/common/charts/barChart';
import ProductComparison from '../../../components/common/compare/ProductComparision';
import { products } from '../../../components/common/compare/Products';


export default function SocialLinkedInPostOverall() {

    const fbPostOverallPostEngagementData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }, { "name": "Instagram", "data": [75, 59, 37, 34, 97, 12, 95, 15] }, { "name": "Twitter", "data": [55, 57, 27, 32, 74, 93, 40, 86] }, { "name": "LinkedIn", "data": [75, 86, 17, 22, 92, 43, 71, 87] }];

    const fbPostOverallPostImpressionData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }];

    const lkPostOrganicInteractionData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }];

    const ttPostOverallVideoViewsData = [{ "name": "No of Videos", "type": "column", "data": [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6] }, { "name": "Video Views", "type": "line", "data": [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5] }];
    const ttPostOverallVideoViewsDataCategories = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]

    const ttPostOverallPollCountData = [{ "name": "No of Polls", "type": "column", "data": [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6] }, { "name": "Vote Count", "type": "line", "data": [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5] }];
    const ttPostOverallPollCountDataCategories = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]

    const leadsChartData = [44, 55];
    const leadsChartDataLabels = ["Desktop", "Mobile"];

    const fbPostOrganicReachByContentTypeData = [44, 55, 41, 17];
    const fbPostOrganicReachByContentTypeDataCategories = ['Video', 'Album', 'Static', 'Link'];

    const instaAudienceCountryData = [7993, 9642, 5521, 9252, 2023, 2175, 5105, 4517, 5552, 5104];
    const instaAudienceCountryCategory = ["India", "UAE", "Japan", "Germany", "Australia", "Switzerland ", "Canada", "United Kingdom", "United States", "Sweden"]


    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Organic Posts</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Impressions</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Unique Impressions / Reach</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Clicks</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Interaction</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>CTR</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Engagement Rate</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Impressions and Reach</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={fbPostOverallPostImpressionData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Object Type</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={leadsChartData} Labels={leadsChartDataLabels} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-1 ">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Interaction</h3>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-1'>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={leadsChartData} Labels={leadsChartDataLabels} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <div className="grid-cols-4 table-content">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={lkPostOrganicInteractionData} Legend={false} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Reactions</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <HalfDonutPieChart ChartId={'fbPostOverallPostReachByContentType'} ChartData={fbPostOrganicReachByContentTypeData} ChartDataCategories={fbPostOrganicReachByContentTypeDataCategories} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Comment Type</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCountry"} ChartData={instaAudienceCountryData} ChartCategory={instaAudienceCountryCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className='grid grid-cols-1'>
                        <div className="card flex justify">
                            <div className="table-header icz_resp_flex_column">
                                <h3>User Profile Clicks</h3>
                            </div>
                            <div className="grid grid-cols-1 table-content">
                                <div className="line_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={fbPostOverallPostImpressionData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ProductComparison products={products} Title={'Top 5 Posts'} />

            </div>
        </Wrapper >
    );
}
