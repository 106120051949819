import { useState } from "react";

import Wrapper from "../../../lib/wrapper";

import CompareFacebookComments from "./compareFacebookComments";
import CompareFacebookLikes from "./compareFacebookLikes";
import CompareFacebookReactions from "./compareFacebookReactions";
import CompareFacebookShares from "./compareFacebookShares";

export default function CompareFacebook() {

    const [postsubnav, setPostsubnav] = useState("Likes");

    const subnav = ["Likes", "Shares", "Comments", "Reactions"];

    const clickHandler = (navtab) => {
        setPostsubnav(navtab)
    }

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className='grid grid-margin-bottom'>
                    <div className="table-header">
                        <div className="group">
                            {subnav.map(navtab => (
                                <button
                                    type="button"
                                    key={navtab}
                                    className={postsubnav === navtab ? "btn btn-tertiary active" : "btn btn-tertiary"}
                                    onClick={() => clickHandler(navtab)}>
                                    {navtab}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <Wrapper >
                    {postsubnav === "Likes" && (
                        <CompareFacebookLikes />
                    )}
                    {postsubnav === "Shares" && (
                        <CompareFacebookShares />
                    )}
                    {postsubnav === "Comments" && (
                        <CompareFacebookComments />
                    )}
                    {postsubnav === "Reactions" && (
                        <CompareFacebookReactions />
                    )}
                </Wrapper>
            </div>
        </Wrapper>

    )
}