import { Component } from "react";
import AnimatedNumber from "animated-number-react";

export default class NumberCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.Number
    }
}

  handleChange = ({ target: { value } }) => {
    this.setState({ value });
  };
  formatValue = (value) => value.toFixed(2);
  render() {

    return (
        <AnimatedNumber
          value={this.state.value}
          formatValue={this.formatValue}
        />
    );
  }
}