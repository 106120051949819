import React from 'react';

import Wrapper from '../../../lib/wrapper';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import NumberCounter from '../../../utils/NumberCounter';

import '../../../../App.scss';


export default function WebsiteTechnologyBrowserUsed() {

    const tabContent = [
        {
            MetricName: "Sessions",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "New Users",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "Bounce Rate",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "Pages / Session",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "New Sessions %",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "AVG Session Duration (Seconds)",
            CurrentData: 300,
            GrowthPerc: 5,
        }
    ];

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h4>Chrome</h4>
                    </div>
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 4
                                }
                            }}>
                            {tabContent.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card card_padded cursor-pointer">
                                        <div className="statbox">
                                            <h4>{table.MetricName}</h4>
                                            <h3><span className="animate_number_decimal"><NumberCounter Number={table.CurrentData} /></span>K</h3>
                                            <div className="statbox_info show">
                                                <span className="green_text block">{table.GrowthPerc}%</span>
                                                <span className="grey_text block">Since Last Month </span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className="grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h4>Safari</h4>
                    </div>
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 4
                                }
                            }}>
                            {tabContent.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card card_padded cursor-pointer">
                                        <div className="statbox">
                                            <h4>{table.MetricName}</h4>
                                            <h3><span className="animate_number_decimal"><NumberCounter Number={table.CurrentData} /></span>K</h3>
                                            <div className="statbox_info show">
                                                <span className="green_text block">{table.GrowthPerc}%</span>
                                                <span className="grey_text block">Since Last Month </span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className="grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h4>UC Browser</h4>
                    </div>
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 4
                                }
                            }}>
                            {tabContent.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card card_padded cursor-pointer">
                                        <div className="statbox">
                                            <h4>{table.MetricName}</h4>
                                            <h3><span className="animate_number_decimal"><NumberCounter Number={table.CurrentData} /></span>K</h3>
                                            <div className="statbox_info show">
                                                <span className="green_text block">{table.GrowthPerc}%</span>
                                                <span className="grey_text block">Since Last Month </span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className="grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h4>Edge</h4>
                    </div>
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 4
                                }
                            }}>
                            {tabContent.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card card_padded cursor-pointer">
                                        <div className="statbox">
                                            <h4>{table.MetricName}</h4>
                                            <h3><span className="animate_number_decimal"><NumberCounter Number={table.CurrentData} /></span>K</h3>
                                            <div className="statbox_info show">
                                                <span className="green_text block">{table.GrowthPerc}%</span>
                                                <span className="grey_text block">Since Last Month </span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
