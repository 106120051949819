import { useState } from "react";

import Wrapper from "../../../lib/wrapper";

import WebsiteTechnologyBrowserUsed from "./websiteTechnologyBrowserUsed";
import WebsiteTechnologyDeviceTrafficType from "./websiteTechnologyDeviceTrafficType";
import WebsiteTechnologyDeviceUsed from "./websiteTechnologyDeviceUsed";


export default function WebsiteTechnology() {

    const [postsubnav, setPostsubnav] = useState("Browser Used");

    const subnav = ["Browser Used", "Device Used", "Device Traffic Type"];

    const clickHandler = (navtab) => {
        setPostsubnav(navtab)
    }

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className='grid grid-margin-bottom'>
                    <div className="table-header">
                        <div className="group">
                            {subnav.map(navtab => (
                                <button
                                    type="button"
                                    key={navtab}
                                    className={postsubnav === navtab ? "btn btn-tertiary active" : "btn btn-tertiary"}
                                    onClick={() => clickHandler(navtab)}>
                                    {navtab}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <Wrapper >
                    {postsubnav === "Browser Used" && (
                        <WebsiteTechnologyBrowserUsed />
                    )}
                    {postsubnav === "Device Used" && (
                        <WebsiteTechnologyDeviceUsed />
                    )}
                    {postsubnav === "Device Traffic Type" && (
                        <WebsiteTechnologyDeviceTrafficType />
                    )}
                </Wrapper>
            </div>
        </Wrapper>

    )
}