import React, { useEffect, useState } from "react";
import Wrapper from "../../../lib/wrapper";

const TablePagination = ({ total = 0, itemsPerPage = 10, currentPage = 1, onPageChange, initialPage = 1 }) => {

    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {

        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));

    }, [total, itemsPerPage])

    if (totalPages === 1) return null;

    return (
        <Wrapper>
            <div className="kmmrce-pagination">
                {currentPage === 1 ? <div onClick={() => onPageChange(currentPage = 1)} className="btn btn-tertiary disabled">1</div> : <div onClick={() => onPageChange(currentPage = 1)} className={currentPage === 1 ? "btn btn-tertiary disabled" : "btn btn-tertiary"}>1</div>}
                {(currentPage - 2) < 1 ? <div className="btn btn-tertiary disabled" >PREV</div> : <div onClick={() => onPageChange(currentPage - 2)} className="btn btn-tertiary" >PREV</div>}
                {currentPage === 1 ? '' : <div onClick={() => onPageChange(currentPage - 1)} className="btn btn-tertiary" >{currentPage - 1}</div>}
                <div className="btn btn-tertiary active">{currentPage}</div>
                {(currentPage + 1) > totalPages ? '' : <div onClick={() => onPageChange(currentPage + 1)} className="btn btn-tertiary" >{currentPage + 1}</div>}
                {(currentPage + 2) > totalPages ? <div className="btn btn-tertiary disabled" >NEXT</div> : <div onClick={() => onPageChange(currentPage + 2)} className="btn btn-tertiary" >NEXT</div>}
                <div onClick={() => onPageChange(totalPages)} className={currentPage === totalPages ? "btn btn-tertiary disabled" : "btn btn-tertiary"}>{totalPages}</div>
            </div>
        </Wrapper>

    )
}

export default TablePagination;