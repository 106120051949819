import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';
import NumberCounter from "../../utils/NumberCounter";
import Wrapper from '../../lib/wrapper';

const SocialTabs = ({ children, active = 0 }) => {
    const [activeTab, setActiveTab] = useState(active);
    const [tabsData, setTabsData] = useState([]);

    useEffect(() => {
        let data = [];

        React.Children.forEach(children, (element) => {
            if (!React.isValidElement(element)) return;
            const {props: { tab, currentValue, growthPercentage, children },} = element;
            data.push({ tab, currentValue, growthPercentage, children });
        });
        setTabsData(data);
    }, [children]);


    return (
        <Wrapper>
            <div className="grid grid-margin-bottom">
                <Swiper
                    modules={[Pagination]}
                    spaceBetween={20}
                    slidesPerView={3}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        0: {
                            slidesPerView: 'auto'
                        },
                        426: {
                            slidesPerView: 2
                        },
                        768: {
                            slidesPerView: 3
                        },
                        1024: {
                            slidesPerView: 3
                        },
                        1600: {
                            slidesPerView: 4
                        }
                    }}>
                    {tabsData.map(({tab ,currentValue, growthPercentage} , idx) => (
                        <SwiperSlide key={idx} role="button" className={`${idx  === activeTab ? "active" : ""}`} onClick={() => setActiveTab(idx)}>
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>{tab}</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={currentValue} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">{growthPercentage}</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* <div className="grid"> */}
            {tabsData[activeTab] && tabsData[activeTab].children}
            {/* </div> */}
        </Wrapper>
    );
};

const TabPane = ({ children }) => {
    return { children };
};

SocialTabs.TabPane = TabPane;

export default SocialTabs;
