import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';

import Wrapper from '../../../lib/wrapper';

import LineChart from '../../../components/common/charts/lineChart';
import NumberCounter from '../../../utils/NumberCounter';
import '../../../../App.scss';
import SelectDropdown from '../../../components/common/selectDropdown/selectDropdown';
import NegativeValueBarChart from '../../../components/common/charts/negativeValueBarChart';
import StackedColumnsChart from '../../../components/common/charts/stackedColumnsChart';

export default function SocialInstagramPage() {

    const pageViewsData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }];
    const pageGrowthData = [{ "name": "Like", "data": [0.4, 0.65, 0.76, 0.88, 1.5, 2.1, 2.9, 3.8, 3.9, 4.2, 4, 4.3, 4.1, 4.2, 4.5, 3.9, 3.5, 3] }, { "name": "Unlike", "data": [-0.8, -1.05, -1.06, -1.18, -1.4, -2.2, -2.85, -3.7, -3.96, -4.22, -4.3, -4.4, -4.1, -4, -4.1, -3.4, -3.1, -2.8] }];
    const likeCurrentPreviousPeriodData = [{ "name": "Ads", "data": [44, 13] }, { "name": "News Feed", "data": [13, 23] }, { "name": "Page Suggestions", "data": [13, 23] }, { "name": "Requests", "data": [13, 23] }, { "name": "Re-activated accouts", "data": [13, 23] }, { "name": "By Search", "data": [13, 23] }, { "name": "Page Direct", "data": [13, 23] }];
    const unlikeCurrentPreviousPeriodData = [{ "name": "By Search", "data": [44, 13] }, { "name": "News Feed", "data": [13, 23] }, { "name": "Posts", "data": [13, 23] }, { "name": "Deactivations", "data": [13, 23] }, { "name": "Suspicious accounts removal", "data": [13, 23] }];
    const currentPreviousPeriodCategory = ["Current Period", "Previous Period"];

    const pageGrowthDropdownOptions = [
        { value: 'like', label: 'Like' },
        { value: 'followers', label: 'Followers' },
    ]

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Like</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Views</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Reach</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Clicks</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Page Views</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={pageViewsData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Page Growth</h3>
                                <SelectDropdown ismulti={false} placeholder={'Select'} options={pageGrowthDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <NegativeValueBarChart ChartId={'pageGrowthChart'} isHorizontal={false} Legend={true} ChartData={pageGrowthData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card grid-margin-bottom'>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Like</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <StackedColumnsChart ChartId={"currentPeriodLikeUnlike"} ChartData={likeCurrentPreviousPeriodData} ChartCategories={currentPreviousPeriodCategory} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Unlike</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <StackedColumnsChart ChartId={"currentPeriodLikeUnlike"} ChartData={unlikeCurrentPreviousPeriodData} ChartCategories={currentPreviousPeriodCategory} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper >
    );
}
