import React from 'react';

import Wrapper from '../../../lib/wrapper';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import '../../../../App.scss';
import NumberCounter from '../../../utils/NumberCounter';
import DataTable from '../../../components/common/datatable/dataTable';


export default function WebsiteTrafficChannelWiseTraffic() {

    const traffic_metric_data = [{"metric_name":"Direct","new_sessions_perc":31,"new_user":70.97,"bounce_rate":70.97,"pages_session":1.84,"avg_session_duration":0,"goal_conversion_rate":2,"goal_completions":0,"goal_value":0},{"metric_name":"Organic Search","new_sessions_perc":31,"new_user":70.97,"bounce_rate":70.97,"pages_session":1.84,"avg_session_duration":0,"goal_conversion_rate":2,"goal_completions":0,"goal_value":0}];

    const tabContent = [
        {
            MetricName: "Sessions",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "New Sessions",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "New Users",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "Bounce Rate %",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "Pages / Session",
            CurrentData: 300,
            GrowthPerc: 5,
        },
        {
            MetricName: "AVG Session Duration (Seconds)",
            CurrentData: 300,
            GrowthPerc: 5,
        }
    ];

    const traffic_metric_data_headers = [
        { name: "Metric Name", field: "metric_name", sortable: true },
        { name: "New User", field: "new_user", sortable: true },
        { name: "Bounce Rate", field: "bounce_rate", sortable: true },
        { name: "Pages/Session", field: "pages_session", sortable: true },
        { name: "Avg. Session Duration", field: "avg_session_duration", sortable: true },
        { name: "Goal Conversion Rate", field: "goal_conversion_rate", sortable: true },
        { name: "Goal Completions", field: "goal_completions", sortable: true },
        { name: "Goal Value", field: "goal_value", sortable: true }
    ]

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h4>Direct</h4>
                    </div>
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 4
                                }
                            }}>
                            {tabContent.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card card_padded cursor-pointer">
                                        <div className="statbox">
                                            <h4>{table.MetricName}</h4>
                                            <h3><span className="animate_number_decimal"><NumberCounter Number={table.CurrentData} /></span>K</h3>
                                            <div className="statbox_info show">
                                                <span className="green_text block">{table.GrowthPerc}%</span>
                                                <span className="grey_text block">Since Last Month </span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className="grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h4>Organic Search</h4>
                    </div>
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 4
                                }
                            }}>
                            {tabContent.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card card_padded cursor-pointer">
                                        <div className="statbox">
                                            <h4>{table.MetricName}</h4>
                                            <h3><span className="animate_number_decimal"><NumberCounter Number={table.CurrentData} /></span>K</h3>
                                            <div className="statbox_info show">
                                                <span className="green_text block">{table.GrowthPerc}%</span>
                                                <span className="grey_text block">Since Last Month </span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className="card fullpage_card">
                    <div className="table-header flex justify">
                        <h3>Summary</h3>
                    </div>
                    <div className="table-content">
                        <div className='table-container'>
                            <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={traffic_metric_data_headers} TableSize={5} TableData={traffic_metric_data} />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
