import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class LineColumnAreaChart extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: this.props.ChartData,
            options: {
                chart: {
                    id: this.props.ChartId,
                    height: 350,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: <svg width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: []
                        },
                        export: {
                            csv: {
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                },

                stroke: {
                    width: [0, 2, 2],
                    curve: 'smooth',
                    lineCap: 'butt',
                    dashArray: [0, 0, 5]
                },

                dataLabels: {
                    enabled: false
                },

                legend: {
                    show: this.props.Legend,
                    showForSingleSeries: true,
                    position: "top",
                    fontFamily: "Yellix",
                    color: 'rgb(55, 61, 63)',
                    fontSize: 12,
                    fontWeight: 400
                },

                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },

                fill: {
                    opacity: [0.85, 0.25, 1, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                labels: this.props.ChartDataLabels,

                markers: {
                    size: 0
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: true,
                        style: {
                            fontFamily: "Yellix",
                            color: 'rgb(55, 61, 63)',
                            fontSize: 11,
                            fontWeight: 400
                        },
                    },
                },
                yaxis: {
                    min: 0,
                    labels: {
                        show: true,
                        style: {
                            fontFamily: "Yellix",
                            color: 'rgb(55, 61, 63)',
                            fontSize: 11,
                            fontWeight: 400
                        },
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + " points";
                            }
                            return y;

                        }
                    }
                }
            },
        }
    }

    render() {

        let ChartType = this.props.ChartType;

        return (
            <Chart
                options={this.state.options}
                series={this.state.series}
                type={ChartType}
                height="365"
            />
        );
    }
};