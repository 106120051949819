import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';

import Wrapper from '../../../lib/wrapper';

import NumberCounter from '../../../utils/NumberCounter';

import LineChart from '../../../components/common/charts/lineChart';
import DonutPieChart from '../../../components/common/charts/donutPieChart';

import '../../../../App.scss';


export default function SocialLinkedInPage() {

    const pageViewsData = [{ "name": "Current", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Previous", "data": [47, 97, 37, 82, 67, 37, 60, 53] }];

    const leadsChartData = [44, 55];
    const leadsChartDataLabels = ["Desktop", "Mobile"];

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Page Views</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Page Reach</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Mentions</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Clicks</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Page Views</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={pageViewsData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Page Reach</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={pageViewsData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Career Page Views</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={leadsChartData} Labels={leadsChartDataLabels} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Jobs Page View</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={leadsChartData} Labels={leadsChartDataLabels} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </Wrapper >
    );
}
