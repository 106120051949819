import React from 'react';

import Wrapper from '../../../lib/wrapper';

import BarChart from '../../../components/common/charts/barChart';
import HeatMapChart from '../../../components/common/charts/heatMapChart';

import '../../../../App.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import NumberCounter from '../../../utils/NumberCounter';

export default function SocialTwitterAudience() {

    const instaAudienceChartData = [40, 30, 40, 45, 50];
    const instaAudienceCategory = ["13-17", "18-24", "25-36", "37-50", "50+"]

    const instaAudienceCountryData = [7993, 9642, 5521, 9252, 2023, 2175, 5105, 4517, 5552, 5104];
    const instaAudienceCountryCategory = ["India", "UAE", "Japan", "Germany", "Australia", "Switzerland ", "Canada", "United Kingdom", "United States", "Sweden"]
    const instaAudienceCityCategory = ["Tokyo", "New York", "Mumbai", "Istanbul", "Chicago", "London ", "Paris", "Dubai", "Abu Dhabi", "Al Sharjah"]

    const tabContent = [
        {
            Name: "Debra White",
            UserName: '@DebraWhite',
            Tweet: "Onto relationship night career knowledge. Understand man matter window. Tonight safe visit environment. Hope hope radio million. First simple college. Data another idea.",
            Date: '28-12-2021 20:40:24',
        },
        {
            Name: "Debra White",
            UserName: '@DebraWhite',
            Tweet: "Onto relationship night career knowledge. Understand man matter window. Tonight safe visit environment. Hope hope radio million. First simple college. Data another idea.",
            Date: '28-12-2021 20:40:24',
        },
        {
            Name: "Debra White",
            UserName: '@DebraWhite',
            Tweet: "Onto relationship night career knowledge. Understand man matter window. Tonight safe visit environment. Hope hope radio million. First simple college. Data another idea.",
            Date: '28-12-2021 20:40:24',
        },
        {
            Name: "Debra White",
            UserName: '@DebraWhite',
            Tweet: "Onto relationship night career knowledge. Understand man matter window. Tonight safe visit environment. Hope hope radio million. First simple college. Data another idea.",
            Date: '28-12-2021 20:40:24',
        },
        {
            Name: "Debra White",
            UserName: '@DebraWhite',
            Tweet: "Onto relationship night career knowledge. Understand man matter window. Tonight safe visit environment. Hope hope radio million. First simple college. Data another idea.",
            Date: '28-12-2021 20:40:24',
        }
    ];


    const topFollowersData = [
        {
            Name: "Ashlee Hust",
            UserName: "@AshleeHust",
            Tweets: 72,
            Following: 38,
            Followers: 100
        },
        {
            Name: "Ashlee Hust",
            UserName: "@AshleeHust",
            Tweets: 72,
            Following: 38,
            Followers: 100
        },
        {
            Name: "Ashlee Hust",
            UserName: "@AshleeHust",
            Tweets: 72,
            Following: 38,
            Followers: 100
        },
        {
            Name: "Ashlee Hust",
            UserName: "@AshleeHust",
            Tweets: 72,
            Following: 38,
            Followers: 100
        },
        {
            Name: "Ashlee Hust",
            UserName: "@AshleeHust",
            Tweets: 72,
            Following: 38,
            Followers: 100
        }
    ];

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Male</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"socialAudienceMale"} ChartData={instaAudienceChartData} ChartCategory={instaAudienceCategory} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Female</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"socialAudienceFemale"} ChartData={instaAudienceChartData} ChartCategory={instaAudienceCategory} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-1 ">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Audience Distribution</h3>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>By Country</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCountry"} ChartData={instaAudienceCountryData} ChartCategory={instaAudienceCountryCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header">
                                <h3>By City</h3>
                            </div>
                            <div className="grid-cols-4 table-content">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCity"} ChartData={instaAudienceCountryData} ChartCategory={instaAudienceCityCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h4>Top 5 Mentions</h4>
                    </div>
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={3}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 3
                                }
                            }}>
                            {tabContent.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card card_padded cursor-pointer">
                                        <div className="statbox">
                                            <h4>{table.Name}</h4>
                                            <span className="green_text block">{table.UserName}</span>
                                            <h5><span className="grey_text block">{table.Tweet}</span></h5>
                                            <div className="statbox_info show">
                                                <span className="grey_text block">{table.Date}</span>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                <div className="grid grid-margin-bottom">
                    <div className="table-header flex justify">
                        <h4>Top 5 Followers</h4>
                    </div>
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 4
                                }
                            }}>
                            {topFollowersData.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card">
                                        <div className="table-header flex justify">
                                            <h3>{table.Name}</h3>
                                        </div>
                                        <div className="table-content">
                                            <table className="table table-bordered table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td>UserName</td>
                                                        <td className="align_right value_td">{table.UserName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Tweets</td>
                                                        <td className="align_right value_td">{table.Tweets}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Following</td>
                                                        <td className="align_right value_td">{table.Following}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Followers</td>
                                                        <td className="align_right value_td">{table.Followers}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

            </div>
        </Wrapper >
    );
}
