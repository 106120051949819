import React, { useState, useCallback } from "react";

import { Link } from 'react-router-dom';

import Wrapper from "../../../lib/wrapper";


export const SideBar = () => {
  const [selectedItem, setSelectedItem] = useState("");

  const toggleBurgerMenu = useCallback(() => {
    document.querySelector("body").classList.toggle("open_menu");
  }, []);
  const handleActiveElement = (e, title) => {
    e.preventDefault();
    setSelectedItem(title);
  };

  const items = [
    {
      title: "CMS",
      items: [
        {
          title: "Pages",
          url: "/dashboard-operational",
          icon: "icon-customers",
          inneritems: [],
        },
        {
          title: "Types",
          url: "/dashboard-operational",
          icon: "icon-dashboard",
          inneritems: [],
        },
        {
          title: "Images",
          url: "/dashboard-operational",
          icon: "icon-catalogue",
          inneritems: [],
        },
      ],
    },
    {
      title: "CONTENT",
      items: [
        {
          title: "Dashboard",
          path: "/performance",
          icon: "icon-dashboard",
          inneritems: [],
        },
        {
          title: "Reports",
          url: "/",
          icon: "icon-catalogue",
          inneritems: [
            {
              title: "Performance",
              path: "",
            },
            {
              title: "Social",
              path: "social",
            },
            {
              title: "Hashtag",
              path: "hashtag",
            },
            {
              title: "Compare",
              path: "compare",
            },
            {
              title: "Website",
              path: "website",
            },
            {
              title: "Video",
              path: "video",
            }
          ],
        },
      ],
    },
  ];

  return (
    <>
      <nav className="sidebar">
        <div className="sidebar-sticky">
          {items.map(({ title, items }, index) => {
            return (
              <Wrapper key={index}>
                <div key={index} className="nav_title">{title}</div>

                {items.map(({ title, icon, inneritems }, index) => (
                  <div key={index}>
                    <ul className="navbar-nav">
                      <li key={index} className={`nav-item dropdown ${title === selectedItem && `active`} `}>
                        <a href="/" className="nav-link inner-menu-toggle" to="/" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false" onClick={(e) => handleActiveElement(e, title)} >
                          <i className={icon}></i>
                          {title}
                        </a>
                        <div className={`inner-menu ${title === selectedItem && `active`}`} aria-labelledby="navbarDropdown" >
                          {inneritems.map(({ title, path, show }, index) => (
                            <Link key={index} to={path} onClick={toggleBurgerMenu} className="inner-menu-item">
                              {title}
                            </Link>
                            // <div key={index}>
                            //   <a className="inner-menu-item" href={`/${path}`} to={`/${path}`} key={index} >
                            //     {title}
                            //   </a>
                            // </div>
                          ))}
                        </div>
                      </li>
                    </ul>
                  </div>
                ))}
              </Wrapper>
            );
          })}
        </div>
      </nav>
      <div className="burger_menu" onClick={toggleBurgerMenu}></div>
    </>
  );
};