import React, { useState, useMemo } from "react"
import Table from 'react-bootstrap/Table'
import Col from 'react-bootstrap/Col';

import Wrapper from '../../../lib/wrapper';

import TableSearch from './tableSearch';
import TableHeader from './tableHeader';
import TablePagination from './tablePagination';


// import './dataTable.scss';

const DataTable = (props) => {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sorting, setSorting] = useState({ field: "", order: "" })

    const [search, setSearch] = useState("");

    const ITEMS_PER_PAGE = props.TableSize;

    const headers = props.Headers;
    const tableData = props.TableData;
    const tableTitle = props.TableTitle;

    const isTableTitleEnable = props.isTableTitleEnable;
    const isFooterEnabled = props.isFooterEnabled;
    const isSearchEnable = props.isSearchEnable;

    const headerCount = headers.length;

    const commentsData = useMemo(() => {
        let computedComments = tableData;
        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.id.toString().includes(search.toLowerCase()) ||
                    comment.name.toLowerCase().includes(search.toLowerCase()) ||
                    comment.email.toLowerCase().includes(search.toLowerCase()) ||
                    comment.body.toLowerCase().includes(search.toLowerCase())
            )
        }
        setTotalItems(computedComments.length);

        // SORTING
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * ((a[sorting.field]).toString()).localeCompare((b[sorting.field]).toString()))
        }

        // CURRENT PAGE SLICE
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [tableData, currentPage, ITEMS_PER_PAGE, search, sorting]);

    return (
        <Wrapper>
            <div>
                <div className="w-100 d-flex flex-wrap">
                    {isTableTitleEnable ?
                        <Col md={6} className="d-flex justify-content-start align-items-center">
                            <h2>{tableTitle}</h2>
                        </Col>
                        : ""}

                    {isSearchEnable ?
                        <Col md={6} className="d-flex justify-content-end">
                            <TableSearch
                                onSearch={
                                    (value) => {
                                        setSearch(value);
                                        setCurrentPage(1)
                                    }
                                }
                            />
                        </Col>
                        : ""}

                </div>
                <Table className="table table-striped table-bordered">
                    <TableHeader
                        headers={headers}
                        onSorting={(field, order) =>
                            setSorting({ field, order })}
                    />
                    {totalItems !== 0 ?
                        <tbody>
                            {commentsData.map((td_info, index) => (
                                <tr key={index} className="even">
                                    {headers.map((th_info, index) => (
                                        <td key={index}>{td_info[th_info.field]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        :
                        <tbody className="even">
                            <td colSpan={headerCount}>
                                <h2>No Data Available</h2>
                            </td>
                        </tbody>
                    }
                </Table>

                {isFooterEnabled ?
                    <div className="w-100 d-flex flex-wrap">
                        <Col md={6} className="d-flex justify-content-end">
                            {totalItems !== 0 ?
                                <TablePagination
                                    total={totalItems}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    currentPage={currentPage}
                                    onPageChange={page => setCurrentPage(page)}
                                    initialPage
                                />
                                : ""}
                        </Col>
                    </div>
                    : ""}

            </div>
        </Wrapper>
    )
}

export default DataTable;