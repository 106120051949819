import React from 'react';

import VideoFacebookTabs from './videoFacebookTabs';

import VideoFacebookPageAnalytics from './videoFacebookPageAnalytics';
import VideoFacebookVideoAnalytics from './videoFacebookVideoAnalytics';

import '../../../../App.scss';


export default function VideoFacebook() {

    const tabContent = [
        {
            title: "Summary",
            content: <VideoFacebookPageAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "Facebook Video 1",
            content: <VideoFacebookVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "Facebook Video 2",
            content: <VideoFacebookVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "Facebook Video 3",
            content: <VideoFacebookVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "Facebook Video 4",
            content: <VideoFacebookVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
        {
            title: "Facebook Video 5",
            content: <VideoFacebookVideoAnalytics />,
            currentValue: 789,
            growthPercentage: 25
        },
    ];

    return (
        <div className="dashboard_home">
            <VideoFacebookTabs >
                {tabContent.map((tab, idx) => (
                    <VideoFacebookTabs.TabPane key={idx} tab={tab.title} currentValue={tab.currentValue} growthPercentage={tab.growthPercentage}>
                        {tab.content}
                    </VideoFacebookTabs.TabPane>
                ))}
            </VideoFacebookTabs>
        </div>
    );
}
