import React from 'react';

import PageHeader from '../../components/common/pageHeader/pageHeader';

import SocialTabs from './socialTabs';
import SocialFacebook from './socialFacebook/socialFacebook';
import SocialInstagram from './socialInstagram/socialInstagram';
import { SocialTwitter } from './socialTwitter/socialTwitter';
import { SocialLinkedIn } from './socialLinkedIn/socialLinkedIn';

import '../../../App.scss';

export default function Social() {

    const tabContent = [
        {
            title: "Facebook",
            currentValue: 300,
            growthPercentage: 5,
            content: <SocialFacebook />,
        },
        {
            title: "Instagram",
            currentValue: 300,
            growthPercentage: 5,
            content: <SocialInstagram />,
        },
        {
            title: "Twitter",
            currentValue: 300,
            growthPercentage: 5,
            content: <SocialTwitter />,
        },
        {
            title: "LinkedIn",
            currentValue: 300,
            growthPercentage: 5,
            content: <SocialLinkedIn />,
        }
    ];

    return (
        <main role="main" className="main_page">
            <div className="content" style={{ "width": "100% !important" }}>
                <div id="messages"></div>
                <div className="container_mobile">
                    <div className="hide_mobile" style={{ "height": "60px" }}></div>
                    <PageHeader PageHeaderTitle={"Social"} />
                </div>
                <div className="page-content">
                    <div className="dashboard_home">
                        <SocialTabs >
                            {tabContent.map((tab, idx) => (
                                <SocialTabs.TabPane key={idx}
                                    tab={tab.title}
                                    currentValue={tab.currentValue}
                                    growthPercentage={tab.growthPercentage}>
                                    {tab.content}
                                </SocialTabs.TabPane>
                            ))}
                        </SocialTabs>
                    </div>
                </div>
            </div>
        </main>
    );
}
