import { useState } from "react";

import Wrapper from "../../../lib/wrapper";

import WebsiteTrafficOrganic from "./websiteTrafficOrganic";
import WebsiteTrafficChannelWiseTraffic from "./websiteTrafficChannelWiseTraffic";


export default function WebsiteTraffic() {

    const [postsubnav, setPostsubnav] = useState("Organic");

    const subnav = ["Organic", "Channel Wise Traffic"];

    const clickHandler = (navtab) => {
        setPostsubnav(navtab)
    }

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className='grid grid-margin-bottom'>
                    <div className="table-header">
                        <div className="group">
                            {subnav.map(navtab => (
                                <button
                                    type="button"
                                    key={navtab}
                                    className={postsubnav === navtab ? "btn btn-tertiary active" : "btn btn-tertiary"}
                                    onClick={() => clickHandler(navtab)}>
                                    {navtab}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <Wrapper >
                    {postsubnav === "Organic" && (
                        <WebsiteTrafficOrganic />
                    )}
                    {postsubnav === "Channel Wise Traffic" && (
                        <WebsiteTrafficChannelWiseTraffic />
                    )}
                </Wrapper>
            </div>
        </Wrapper>

    )
}