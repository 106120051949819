const IconSortUp = (props) => {
    return (
        <svg className={props.className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 8.501L10.5 4.5L6.5 8.501" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 4.5L10.5 17.5" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}

export default IconSortUp;