import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class HalfDonutPieChart extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: this.props.ChartData,
            options: {
                chart: {
                    id: this.props.ChartId,
                    height: 390,
                    type: 'donut',
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: <svg width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: []
                        },
                        export: {
                            csv: {
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                },

                dataLabels: {
                    enabled: false
                },

                legend: {
                    show: this.props.Legend,
                    showForSingleSeries: true,
                    position: "bottom",
                    fontFamily: "Yellix",
                    color: 'rgb(55, 61, 63)',
                    fontSize: 12,
                    fontWeight: 400
                },

                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 90,
                        offsetY: 20,
                    }
                },
                grid: {
                    padding: {
                        bottom: -80
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };
    }

    render() {


        return (
            <Chart
                options={this.state.options}
                series={this.state.series}
                type="donut"
                height={390}
            />
        );
    }
};