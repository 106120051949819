import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class BubbleChart extends Component {

  // generateData = (baseval, count, yrange) => {
  //     var i = 0;
  //     var series = [];
  //     while (i < count) {
  //       var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;;
  //       var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
  //       var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

  //       series.push([x, y, z]);
  //       baseval += 86400000;
  //       i++;
  //     }
  //     return series;
  //   }

  constructor(props) {
    super(props);

    this.state = {

      series: this.props.ChartData,

      options: {
        chart: {
          id: this.props.ChartId,
          height: 350,
          type: 'bubble',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: <svg width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },

        dataLabels: {
          enabled: false
        },

        legend: {
          show: this.props.Legend,
          showForSingleSeries: true,
          position: "top",
          fontFamily: "Yellix",
          color: 'rgb(55, 61, 63)',
          fontSize: 12,
          fontWeight: 400
        },

        fill: {
          opacity: 0.8
        },

        xaxis: {
          tickAmount: 12,
          type: 'category',
          labels: {
            show: true,
            style: {
              fontFamily: "Yellix",
              color: 'rgb(55, 61, 63)',
              fontSize: 11,
              fontWeight: 400
            },
          },
        },

        yaxis: {
          // max: 70,
          labels: {
            show: true,
            style: {
              fontFamily: "Yellix",
              color: 'rgb(55, 61, 63)',
              fontSize: 11,
              fontWeight: 400
            },
          },
        }

      },


    };

  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bubble"
        height="365"
      />
    );
  }
};