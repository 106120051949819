import React from 'react';

import PageHeader from '../../components/common/pageHeader/pageHeader';

import VideoTabs from './videoTabs';

import VideoFacebook from './videoFacebook/videoFacebook';


import '../../../App.scss';
import VideoYouTube from './videoYoutube/videoYouTube';


export default function Video() {

    const tabContent = [
        {
            title: "Facebook",
            currentValue: 300,
            growthPercentage: 5,
            content: <VideoFacebook />,
        },
        {
            title: "YouTube",
            currentValue: 300,
            growthPercentage: 5,
            content: <VideoYouTube />,
        }
    ];

    return (
        <main role="main" className="main_page">
            <div className="content" style={{ "width": "100% !important" }}>
                <div id="messages"></div>
                <div className="container_mobile">
                    <div className="hide_mobile" style={{ "height": "60px" }}></div>
                    <PageHeader PageHeaderTitle={"Video"} />
                </div>
                <div className="page-content">
                    <div className="dashboard_home">
                        <VideoTabs >
                            {tabContent.map((tab, idx) => (
                                <VideoTabs.TabPane key={idx}
                                    tab={tab.title}
                                    currentValue={tab.currentValue}
                                    growthPercentage={tab.growthPercentage}>
                                    {tab.content}
                                </VideoTabs.TabPane>
                            ))}
                        </VideoTabs>
                    </div>
                </div>
            </div>
        </main>
    );
}
