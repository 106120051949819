import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';

import { Image } from 'react-bootstrap';

import Wrapper from '../../../lib/wrapper';

import NumberCounter from '../../../utils/NumberCounter';

import SelectDropdown from '../../../components/common/selectDropdown/selectDropdown';

import DataTable from '../../../components/common/datatable/dataTable';

import LineChart from '../../../components/common/charts/lineChart';
import BarChart from '../../../components/common/charts/barChart';
import StackedColumnsChart from '../../../components/common/charts/stackedColumnsChart';
import MultipleYAxisChart from '../../../components/common/charts/multipleYAxisChart';
import HeatMapChart from '../../../components/common/charts/heatMapChart';
import LineColumnAreaChart from '../../../components/common/charts/lineColumnAreaChart';
import DonutPieChart from '../../../components/common/charts/donutPieChart';

import YoutubeThumbnail from '../../../assets/images/video-thumbnail.jpg';

import ProductComparison from '../../../components/common/compare/ProductComparision';
import { products } from '../../../components/common/compare/Products';

import '../../../../App.scss';
import { hideForKmmrce } from '../../../lib/constants';



export default function VideoFacebookPageAnalyticsPaid() {

    const contentAnalysisData = [{ "name": "3 Sec", "data": [44, 13, 26] }, { "name": "10 Sec", "data": [13, 23, 74] }, { "name": "30 Sec", "data": [45, 79, 13] }, { "name": "Full Video", "data": [11, 34, 28] }];
    const contentAnalysisDataCategory = ['Snackable < 30s', 'Short < 120s', 'Long > 120s'];

    const viewTrendAnalysisData = [{ "name": "Snackable < 30s", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Short < 120s", "data": [47, 97, 37, 82, 67, 37, 60, 53] }, { "name": "Long > 120s", "data": [75, 59, 37, 34, 97, 12, 95, 15] }];
    const viewTrendAnalysisDataKMMRCE = [{ "name": "3s", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "10s", "data": [47, 97, 37, 82, 67, 37, 60, 53] }, { "name": "30s", "data": [75, 59, 37, 34, 97, 12, 95, 15] }];

    const playingBehaviourData = [{ "name": "3 Sec", "data": [44, 13, 26] }, { "name": "10 Sec", "data": [13, 23, 74] }, { "name": "30 Sec", "data": [45, 79, 13] }];
    const playingBehaviourDataCategory = ['Auto played', 'Click to play', 'Repeat'];

    const uniqueViewsAnalysisData = [{ "name": "Views", "type": "column", "data": [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6] }, { "name": "Unique Users", "type": "line", "data": [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5] }];
    const uniqueViewsAnalysisDataCategories = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]

    const videoActivityTrendData = [{ "name": "Mon", "data": [73, 68, 10, 24, 43, 59, 75, 65, 11, 76, 27, 56, 40, 84, 81, 86, 9, 35, 31, 7, 62, 38, 59, 73] }, { "name": "Tue", "data": [11, 4, 61, 87, 81, 40, 44, 76, 74, 53, 77, 68, 19, 30, 87, 54, 59, 88, 16, 47, 11, 77, 40, 14,] }, { "name": "Wed", "data": [59, 4, 21, 7, 3, 43, 59, 67, 51, 33, 48, 82, 21, 84, 2, 75, 44, 80, 67, 24, 48, 44, 4, 6,] }, { "name": "Thu", "data": [31, 81, 56, 46, 46, 13, 35, 16, 84, 83, 54, 14, 33, 33, 73, 82, 53, 23, 27, 78, 84, 27, 47, 76] }, { "name": "Fri", "data": [47, 53, 71, 50, 85, 15, 86, 56, 70, 40, 18, 90, 76, 49, 69, 70, 46, 42, 66, 50, 32, 29, 21, 43] }, { "name": "Sat", "data": [10, 59, 89, 46, 11, 57, 4, 35, 26, 56, 21, 52, 44, 46, 26, 77, 66, 70, 73, 63, 69, 44, 84, 24] }, { "name": "Sun", "data": [64, 65, 71, 8, 10, 41, 44, 21, 77, 12, 38, 7, 64, 65, 71, 8, 10, 41, 44, 21, 10, 41, 44, 21] }];

    const videoRateAnalysisData = [{ "name": "Video Completion Rate", "type": "column", "data": [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30] }, { "name": "Audience Retention Rate", "type": "area", "data": [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43] }, { "name": "Engagement rate", "type": "line", "data": [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39] }];
    const videoRateAnalysisDataLabels = ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003', '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'];

    const videoPositiveSentimentData = [44, 55, 41, 17];
    const videoPositiveSentimentDataLabels = ["Like", "Love", "WOW", "HAHA"];

    const videoNegativeSentimentData = [44, 55];
    const videoNegativeSentimentDataLabels = ["Sad", "Anger"];

    const videoCommentsSentimentData = [44, 55, 41];
    const videoCommentsSentimentDataLabels = ["Positive", "Negative", "Neutral"];

    const audienceChartData = [40, 30, 40, 45, 50];
    const audienceCategory = ["13-17", "18-24", "25-36", "37-50", "50+"]

    const audienceCountryData = [7993, 9642, 5521, 9252, 2023, 2175, 5105, 4517, 5552, 5104];
    const audienceCountryCategory = ["India", "UAE", "Japan", "Germany", "Australia", "Switzerland ", "Canada", "United Kingdom", "United States", "Sweden"]
    const audienceCityCategory = ["Tokyo", "New York", "Mumbai", "Istanbul", "Chicago", "London ", "Paris", "Dubai", "Abu Dhabi", "Al Sharjah"]

    const topPeformingVideosTableData = [{ "Thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "Title": "Big Question About Commerce You Should Know How To Answer", "Views": 123, "Impressions": 456, "Avg View Duration": 789, "Unique viewers": 147, "Reactions": 462, "Comments": 335, "Shares": 87, "Total time viewed": 841, "Complete video views": 664 }, { "Thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "Title": "15 Best Commerce Bloggers You Need To Follow", "Views": 258, "Impressions": 369, "Avg View Duration": 987, "Unique viewers": 654, "Reactions": 412, "Comments": 632, "Shares": 87, "Total time viewed": 221, "Complete video views": 445 }, { "Thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "Title": "Don't Buy Into These Trends About Commerce", "Views": 321, "Impressions": 159, "Avg View Duration": 357, "Unique viewers": 951, "Reactions": 888, "Comments": 417, "Shares": 87, "Total time viewed": 996, "Complete video views": 991 }, { "Thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "Title": "Why People Love To Hate Commerce", "Views": 753, "Impressions": 248, "Avg View Duration": 268, "Unique viewers": 943, "Reactions": 663, "Comments": 898, "Shares": 87, "Total time viewed": 774, "Complete video views": 664 }, { "Thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "Title": "Commerce Doesn't Have To Be Hard, Here Are My Tips", "Views": 971, "Impressions": 931, "Avg View Duration": 179, "Unique viewers": 139, "Reactions": 332, "Comments": 665, "Shares": 87, "Total time viewed": 112, "Complete video views": 335 }];


    // DROPDOWN OPTIONS DATA
    const topPeformingVideosTableDataHeaders = [
        { name: "Thumbnail", field: "thumbnail", sortable: false },
        { name: "Title", field: "title", sortable: true },
        { name: "Views", field: "views", sortable: true },
        { name: "Impressions", field: "impressions", sortable: true },
        { name: "Avg View Duration", field: "avg_view_duration", sortable: true },
        { name: "Unique viewers", field: "unique_viewers", sortable: true },
        { name: "Reactions", field: "reactions", sortable: true },
        { name: "Comments", field: "comments", sortable: true },
        { name: "Shares", field: "shares", sortable: true },
        { name: "Total time viewed", field: "total_time_viewed", sortable: true },
        { name: "Complete video views", field: "complete_video_views", sortable: true }
    ]

    const viewTrendAnalysisDropdownOptions = [
        { value: '3s', label: '3 Sec' },
        { value: '10s', label: '10 Sec' },
        { value: '30s', label: '30 Sec' },
        { value: 'complete', label: 'Full Video' }
    ]

    const uniqueViewsAnalysisDropdownOptions = [
        { value: '10s', label: '10 Sec' },
        { value: '30s', label: '30 Sec' },
        { value: 'complete', label: 'Full Video' }
    ]

    const videoActivityTrendDropdownOptions = [
        { value: '3s', label: '3 Sec' },
        { value: '10s', label: '10 Sec' },
        { value: '30s', label: '30 Sec' },
        { value: 'complete', label: 'Full Video' },
        { value: 'soundOnViews', label: 'Sound On Views' },
        { value: 'clickToPlay  ', label: 'Click To Play  ' },
        { value: 'autoplayedViews', label: 'Autoplayed Views' }
    ];

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Videos</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Video Views (in minutes)</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Avg Time Viewed</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Avg View Per Video Post</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Unique Viewers</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>View Through Rate</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Paid Views</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Returning views</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}
                    </Swiper>
                </div>

                {/* <div className='grid grid-cols-4 grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Content Analysis</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <StackedColumnsChart ChartId={"contentAnalysis"} ChartData={contentAnalysisData} ChartCategories={contentAnalysisDataCategory} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>View Trend Analysis</h3>
                                <SelectDropdown ismulti={false} placeholder={'Select'} options={viewTrendAnalysisDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"viewTrendAnalysis"} ChartType={"area"} ChartData={viewTrendAnalysisData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className={!hideForKmmrce ? 'grid grid-cols-4 grid-margin-bottom' : 'grid grid-cols-1 grid-margin-bottom'}>
                    {!hideForKmmrce ?
                        <div className='grid col-span-2'>
                            <div className="grid card">
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Content Analysis</h3>
                                </div>
                                <div className="grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                        <StackedColumnsChart ChartId={"contentAnalysis"} ChartData={contentAnalysisData} ChartCategories={contentAnalysisDataCategory} Legend={true} Stroke={true} />
                                    </div>
                                </div>
                            </div>
                        </div> : ''}
                    <div className={!hideForKmmrce ? 'grid col-span-1' : 'grid col-span-2'}>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>View Trend Analysis</h3>
                                {!hideForKmmrce ?
                                    <SelectDropdown ismulti={false} placeholder={'Select'} options={viewTrendAnalysisDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" /> : ''}
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"viewTrendAnalysis"} ChartType={"area"} ChartData={!hideForKmmrce ? viewTrendAnalysisData : viewTrendAnalysisDataKMMRCE} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {!hideForKmmrce ?
                <div className='grid grid-cols-1 grid-margin-bottom'>
                    <div className="grid card">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Unique Views Analysis</h3>
                            <SelectDropdown ismulti={false} placeholder={'Select'} options={uniqueViewsAnalysisDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                        </div>
                        <div className="grid-cols-1 table-content icz_resp_flex_column">
                            <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                <MultipleYAxisChart
                                    ChartId={'fbPostOverallVideoViews'}
                                    Legend={true}
                                    ChartData={uniqueViewsAnalysisData}
                                    ChartDataCategories={uniqueViewsAnalysisDataCategories} />
                            </div>
                        </div>
                    </div>
                </div>: '' }

                {!hideForKmmrce ?
                <div className='grid grid-cols-1 grid-margin-bottom'>
                    <div className="card">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Video Activity Trend</h3>
                            <SelectDropdown ismulti={false} placeholder={'Select'} options={videoActivityTrendDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                        </div>
                        <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                            <div className="col-span-4">
                                <div className="line_chart_holder padded_table_sec">
                                    <div id="line-chart"></div>
                                    <HeatMapChart ChartId={"audienceActivityTrendChart"} ChartData={videoActivityTrendData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>: ''}

                {!hideForKmmrce ?
                <div className='grid grid-cols-1 grid-margin-bottom'>
                    <div className="card">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Video Rate Analysis</h3>
                        </div>
                        <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                            <div className="col-span-4">
                                <div className="line_chart_holder padded_table_sec">
                                    <div id="line-chart"></div>
                                    <LineColumnAreaChart ChartId={"videoActivityTrendChart"} ChartDataLabels={videoRateAnalysisDataLabels} ChartData={videoRateAnalysisData} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>: ''}

                {!hideForKmmrce ?
                    <div className='card grid grid-margin-bottom'>
                        <div className="grid grid-cols-1 ">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Video Sentiment</h3>
                            </div>
                        </div>
                        <div className="grid grid-cols-3">
                            <div className='col-span-1'>
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Positive Reaction Sentiment</h3>
                                </div>
                                <div className="grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="col-span-3">
                                        <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                            <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={videoPositiveSentimentData} Labels={videoPositiveSentimentDataLabels} Legend={true} />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="table-content">
                                            <table className="table table-striped table-bordered table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-10">Like</td>
                                                        <td className="col-md-2 align_right value_td">1.6M</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Love</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">WOW</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">HAHA</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10"><em>Total Reactions</em></td>
                                                        <td className="col-md-2 align_right value_td"><em>800K</em></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-1'>
                                <div className="table-header">
                                    <h3>Negative Reaction Sentiment</h3>
                                </div>
                                <div className="grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="col-span-3">
                                        <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                            <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={videoNegativeSentimentData} Labels={videoNegativeSentimentDataLabels} Legend={true} />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="table-content">
                                            <table className="table table-striped table-bordered table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-10">Sad</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Anger</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10"><em>Total Reactions</em></td>
                                                        <td className="col-md-2 align_right value_td"><em>800K</em></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-1'>
                                <div className="table-header">
                                    <h3>Comment Sentiment</h3>
                                </div>
                                <div className="grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="col-span-3">
                                        <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                            <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={videoCommentsSentimentData} Labels={videoCommentsSentimentDataLabels} Legend={true} />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="table-content">
                                            <table className="table table-striped table-bordered table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-10">Positive</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Negative</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Neutral</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10"><em>Total Comments</em></td>
                                                        <td className="col-md-2 align_right value_td"><em>800K</em></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}

                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-1 ">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Audience Demographics</h3>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Male</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"socialAudienceMale"} ChartData={audienceChartData} ChartCategory={audienceCategory} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Female</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"socialAudienceFemale"} ChartData={audienceChartData} ChartCategory={audienceCategory} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-1 ">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Audience Distribution</h3>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>By Country</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCountry"} ChartData={audienceCountryData} ChartCategory={audienceCountryCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header">
                                <h3>By City</h3>
                            </div>
                            <div className="grid-cols-4 table-content">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCity"} ChartData={audienceCountryData} ChartCategory={audienceCityCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {!hideForKmmrce ?
                    <div className="grid grid-cols-1 grid-margin-bottom">
                        <div className="card flex justify">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Top Performing Paid Videos</h3>
                            </div>
                            <div className="grid grid-cols-1 table-content">
                                <div className="line_chart_holder padded_table_sec">
                                    <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={false} Headers={topPeformingVideosTableDataHeaders} TableSize={5} TableData={topPeformingVideosTableData} />
                                </div>
                            </div>
                        </div>
                    </div> : ''}

                <ProductComparison products={products} Title={'Top 5 Videos'} />

            </div>
        </Wrapper >
    );
}
