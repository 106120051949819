import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: this.props.ChartId,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: <svg width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              }
            },
            autoSelected: 'zoom'
          },
        },

        stroke: {
          show: this.props.Stroke,
          curve: 'smooth',
          lineCap: 'butt',
          width: 2,
          dashArray: 0,
        },

        dataLabels: {
          enabled: false
        },

        legend: {
          show: this.props.Legend,
          showForSingleSeries: true,
          position: "top",
          fontFamily: "Yellix",
          color: 'rgb(55, 61, 63)',
          fontSize: 12,
          fontWeight: 400
        },

        xaxis: {
          categories: this.props.ChartDataCategory,
          labels: {
            show: true,
            style: {
              fontFamily: "Yellix",
              color: 'rgb(55, 61, 63)',
              fontSize: 11,
              fontWeight: 400
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontFamily: "Yellix",
              color: 'rgb(55, 61, 63)',
              fontSize: 11,
              fontWeight: 400
            },
          },
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: this.props.isHorizontal,
        }
      },
      series: this.props.ChartData,
    };
  }

  render() {

    let ChartType = this.props.ChartType;

    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type={ChartType}
        height="365"
      />
    );
  }
};