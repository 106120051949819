import React from 'react';

import Wrapper from '../../../lib/wrapper';

import BarChart from '../../../components/common/charts/barChart';
import HeatMapChart from '../../../components/common/charts/heatMapChart';

import '../../../../App.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import NumberCounter from '../../../utils/NumberCounter';
import LineChart from '../../../components/common/charts/lineChart';
import DonutPieChart from '../../../components/common/charts/donutPieChart';
import BubbleChart from '../../../components/common/charts/bubbleChart';

export default function SocialLinkedInAudience() {

    const instaAudienceChartData = [40, 30, 40, 45, 50];
    const instaAudienceCategory = ["13-17", "18-24", "25-36", "37-50", "50+"]

    const instaAudienceCountryData = [7993, 9642, 5521, 9252, 2023, 2175, 5105, 4517, 5552, 5104];
    const instaAudienceCountryCategory = ["India", "UAE", "Japan", "Germany", "Australia", "Switzerland ", "Canada", "United Kingdom", "United States", "Sweden"]
    const instaAudienceCityCategory = ["Tokyo", "New York", "Mumbai", "Istanbul", "Chicago", "London ", "Paris", "Dubai", "Abu Dhabi", "Al Sharjah"]

    const fbPostOverallPostImpressionData = [{ "name": "New Followers", "data": [30, 40, 45, 50, 49, 60, 70, 91] }];

    const leadsChartData = [44, 55, 74, 87];
    const leadsChartDataLabels = ["Manager", "Team Leads", "Sr. Executive", "Executive"];

    const engagementChartData = [{ "name": "2-10", "data": [[230, 11, 130]] }, { "name": "11-50", "data": [[259, 48, 120]] }, { "name": "51-200", "data": [[299, 23, 110]] }, { "name": "201-500", "data": [[259, 30, 100]] }];


    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Followers</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Followers Growth</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Organic Follower</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Paid Follower</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card flex justify">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Tweet Impressions</h3>
                        </div>
                        <div className="grid grid-cols-1 table-content">
                            <div className="line_chart_holder padded_table_sec">
                                <LineChart ChartId={"performanceBySource"} ChartType={"area"} ChartData={fbPostOverallPostImpressionData} Legend={false} Stroke={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Male</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"socialAudienceMale"} ChartData={instaAudienceChartData} ChartCategory={instaAudienceCategory} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Female</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"socialAudienceFemale"} ChartData={instaAudienceChartData} ChartCategory={instaAudienceCategory} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-1 ">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Audience Distribution</h3>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>By Country</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCountry"} ChartData={instaAudienceCountryData} ChartCategory={instaAudienceCountryCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header">
                                <h3>By City</h3>
                            </div>
                            <div className="grid-cols-4 table-content">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCity"} ChartData={instaAudienceCountryData} ChartCategory={instaAudienceCityCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-1 ">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Industry and Designation</h3>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCountry"} ChartData={leadsChartData} ChartCategory={leadsChartDataLabels} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="grid-cols-4 table-content">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={leadsChartData} Labels={leadsChartDataLabels} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card">
                        <div className="table-header flex justify icz_resp_flex_column">
                            <h3>Company size wise distribution</h3>
                        </div>
                        <div className="table-content">
                            <div className='line_chart_holder radial_chart_holder padded_table_sec'>
                                <BubbleChart ChartId={"engagementChart"} Legend={true} ChartData={engagementChartData} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Wrapper >
    );
}
