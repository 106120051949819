import React, { useState } from 'react';

import Wrapper from '../../lib/wrapper';

import PageHeader from '../../components/common/pageHeader/pageHeader';

import CompareFacebook from './compareFacebook/compareFacebook';
import CompareInstagram from './compareInstagram/compareInstagram';
import CompareTwitter from './compareTwitter/compareTwitter';

import '../../../App.scss';

const Compare = () => {

    const [activeTab, setActiveTab] = useState("Facebook");

    const subnav = ["Facebook", "Instagram", "Twitter"];

    const clickHandler = (navtab) => {
        setActiveTab(navtab)
    }

    return (
        <Wrapper>
            <main role="main" className="main_page">
                <div className="content" style={{ "width": "100% !important" }}>
                    <div id="messages"></div>
                    <div className="container_mobile">
                        <div className="hide_mobile" style={{ "height": "60px" }}></div>
                        <PageHeader PageHeaderTitle={"Compare"} />
                    </div>
                    <div className="page-content">
                        <div className="dashboard_home">
                            <ul className="tab_navigation">
                                {subnav.map((navtab, index) => {
                                    return (
                                        <li key={index} className="tab_navigation_item grid grid-margin-bottom">
                                            <a
                                                className={`tab_navigation_link ${activeTab === navtab ? "active" : ""}`}
                                                href={`#${navtab}`} onClick={() => clickHandler(navtab)}>
                                                {navtab}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="tab_content">
                                <Wrapper >
                                    {activeTab === "Facebook" && (
                                        <CompareFacebook />
                                    )}
                                    {activeTab === "Instagram" && (
                                        <CompareInstagram />
                                    )}
                                    {activeTab === "Twitter" && (
                                        <CompareTwitter />
                                    )}
                                </Wrapper >
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Wrapper >
    );
}

export default Compare;
