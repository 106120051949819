import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';

import { Image } from 'react-bootstrap';

import Wrapper from '../../../lib/wrapper';

import NumberCounter from '../../../utils/NumberCounter';

import SelectDropdown from '../../../components/common/selectDropdown/selectDropdown';

import DataTable from '../../../components/common/datatable/dataTable';

import LineChart from '../../../components/common/charts/lineChart';
import BarChart from '../../../components/common/charts/barChart';
import StackedColumnsChart from '../../../components/common/charts/stackedColumnsChart';
import HeatMapChart from '../../../components/common/charts/heatMapChart';
import DonutPieChart from '../../../components/common/charts/donutPieChart';
import TreemapChart from '../../../components/common/charts/treemapChart';

import YoutubeThumbnail from '../../../assets/images/video-thumbnail.jpg';

import ProductComparison from '../../../components/common/compare/ProductComparision';
import { products } from '../../../components/common/compare/Products';

import '../../../../App.scss';
import { hideForKmmrce } from '../../../lib/constants';



export default function VideoYouTubePageAnalytics() {

    const contentAnalysisData = [{ "name": "3 Sec", "data": [44, 13, 26] }, { "name": "10 Sec", "data": [13, 23, 74] }, { "name": "30 Sec", "data": [45, 79, 13] }, { "name": "Full Video", "data": [11, 34, 28] }];
    const contentAnalysisDataCategory = ['Snackable < 30s', 'Short < 120s', 'Long > 120s'];

    const viewTrendAnalysisData = [{ "name": "Snackable < 30s", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Short < 120s", "data": [47, 97, 37, 82, 67, 37, 60, 53] }, { "name": "Long > 120s", "data": [75, 59, 37, 34, 97, 12, 95, 15] }];

    const subscribersData = [{ "name": "Subscribers Gains", "data": [30, 40, 45, 50, 49, 60, 70, 91] }, { "name": "Subscribers Losts", "data": [47, 97, 37, 82, 67, 37, 60, 53] }];

    const videoActivityTrendData = [{ "name": "Mon", "data": [73, 68, 10, 24, 43, 59, 75, 65, 11, 76, 27, 56, 40, 84, 81, 86, 9, 35, 31, 7, 62, 38, 59, 73] }, { "name": "Tue", "data": [11, 4, 61, 87, 81, 40, 44, 76, 74, 53, 77, 68, 19, 30, 87, 54, 59, 88, 16, 47, 11, 77, 40, 14,] }, { "name": "Wed", "data": [59, 4, 21, 7, 3, 43, 59, 67, 51, 33, 48, 82, 21, 84, 2, 75, 44, 80, 67, 24, 48, 44, 4, 6,] }, { "name": "Thu", "data": [31, 81, 56, 46, 46, 13, 35, 16, 84, 83, 54, 14, 33, 33, 73, 82, 53, 23, 27, 78, 84, 27, 47, 76] }, { "name": "Fri", "data": [47, 53, 71, 50, 85, 15, 86, 56, 70, 40, 18, 90, 76, 49, 69, 70, 46, 42, 66, 50, 32, 29, 21, 43] }, { "name": "Sat", "data": [10, 59, 89, 46, 11, 57, 4, 35, 26, 56, 21, 52, 44, 46, 26, 77, 66, 70, 73, 63, 69, 44, 84, 24] }, { "name": "Sun", "data": [64, 65, 71, 8, 10, 41, 44, 21, 77, 12, 38, 7, 64, 65, 71, 8, 10, 41, 44, 21, 10, 41, 44, 21] }];

    const trafficAnalysisData = [{ "x": "Direct or unknown", "y": 218 }, { "x": "YouTube advertising", "y": 218 }, { "x": "Browse features", "y": 218 }, { "x": "YouTube channels", "y": 218 }, { "x": "YouTube search", "y": 218 }];

    const videoPositiveSentimentData = [44, 55, 41, 17];
    const videoPositiveSentimentDataLabels = ["Like", "Love", "WOW", "HAHA"];

    const videoCommentsSentimentData = [44, 55, 41];
    const videoCommentsSentimentDataLabels = ["Positive", "Negative", "Neutral"];

    const audienceChartData = [40, 30, 40, 45, 50];
    const audienceCategory = ["13-17", "18-24", "25-36", "37-50", "50+"]

    const audienceCountryData = [7993, 9642, 5521, 9252, 2023, 2175, 5105, 4517, 5552, 5104];
    const audienceCountryCategory = ["India", "UAE", "Japan", "Germany", "Australia", "Switzerland ", "Canada", "United Kingdom", "United States", "Sweden"]

    const topPeformingVideosTableData = [{ "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "Big Question About Commerce You Should Know How To Answer", "views": 123, "minutes_viewed": 456, "avg_view_duration": 789, "Viewer Percentage (Logged in)": 147, "likes": 462, "dislikes": 335, "comments": 841 }, { "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "15 Best Commerce Bloggers You Need To Follow", "views": 258, "minutes_viewed": 369, "avg_view_duration": 987, "Viewer Percentage (Logged in)": 654, "likes": 412, "dislikes": 632, "comments": 221 }, { "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "Don't Buy Into These Trends About Commerce", "views": 321, "minutes_viewed": 159, "avg_view_duration": 357, "Viewer Percentage (Logged in)": 951, "likes": 888, "dislikes": 417, "comments": 996 }, { "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "Why People Love To Hate Commerce", "views": 753, "minutes_viewed": 248, "avg_view_duration": 268, "Viewer Percentage (Logged in)": 943, "likes": 663, "dislikes": 898, "comments": 774 }, { "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "Commerce Doesn't Have To Be Hard, Here Are My Tips", "views": 971, "minutes_viewed": 931, "avg_view_duration": 179, "Viewer Percentage (Logged in)": 139, "likes": 332, "dislikes": 665, "comments": 112 }];
    const topPeformingVideosTableDataKMMRCE = [{ "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "Big Question About Commerce You Should Know How To Answer", "views": 123, "minutes_viewed": 456, "avg_view_duration": 789, "likes": 462, "dislikes": 335, "comments": 841, "shares": 112 }, { "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "15 Best Commerce Bloggers You Need To Follow", "views": 258, "minutes_viewed": 369, "avg_view_duration": 987, "likes": 412, "dislikes": 632, "comments": 221, "shares": 112 }, { "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "Don't Buy Into These Trends About Commerce", "views": 321, "minutes_viewed": 159, "avg_view_duration": 357, "likes": 888, "dislikes": 417, "comments": 996, "shares": 112 }, { "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "Why People Love To Hate Commerce", "views": 753, "minutes_viewed": 248, "avg_view_duration": 268, "likes": 663, "dislikes": 898, "comments": 774, "shares": 112 }, { "thumbnail": <Image src={YoutubeThumbnail} style={{ 'width': '100%' }} />, "title": "Commerce Doesn't Have To Be Hard, Here Are My Tips", "views": 971, "minutes_viewed": 931, "avg_view_duration": 179, "likes": 332, "dislikes": 665, "comments": 112, "shares": 112 }];


    // DROPDOWN OPTIONS DATA
    const topPeformingVideosTableDataHeaders = [
        { name: "Thumbnail", field: "thumbnail", sortable: false },
        { name: "Title", field: "title", sortable: true },
        { name: "Views", field: "views", sortable: true },
        { name: "Minutes Viewed", field: "minutes_viewed", sortable: true },
        { name: "Avg View Duration", field: "avg_view_duration", sortable: true },
        { name: "Viewer Percentage (Logged in)", field: "viewer_percentage_logged_in", sortable: true },
        { name: "Likes", field: "likes", sortable: true },
        { name: "Dislikes", field: "dislikes", sortable: true },
        { name: "Comments", field: "comments", sortable: true }
    ]

    const topPeformingVideosTableDataHeadersKMMRCE = [
        { name: "Thumbnail", field: "thumbnail", sortable: false },
        { name: "Title", field: "title", sortable: true },
        { name: "Views", field: "views", sortable: true },
        { name: "Minutes Viewed", field: "minutes_viewed", sortable: true },
        { name: "Avg View Duration", field: "avg_view_duration", sortable: true },
        { name: "Likes", field: "likes", sortable: true },
        { name: "Dislikes", field: "dislikes", sortable: true },
        { name: "Comments", field: "comments", sortable: true },
        { name: "Shares", field: "shares", sortable: true }
    ]



    const viewTrendAnalysisDropdownOptions = [
        { value: 'total_views', label: 'Total Views' },
        { value: 'premium_youtubers_views', label: "Premium Youtuber's Views" },
        { value: 'avg_view_duration', label: 'Avg View Duration' },
        { value: 'viewer_perc', label: 'Viewer %' }
    ]

    const trafficAnalysisDropdownOptions = [
        { value: 'no_of_views', label: 'No of views' },
        { value: 'avg_view_duration', label: 'Avg View Duration' },
        { value: 'viewer%', label: 'Viewer %' }
    ]

    const trafficAnalysisSourceDropdownOptions = [
        { value: 'source_type', label: 'Source type' },
        { value: 'source_details', label: 'Source Details' },
        { value: 'playBack_location', label: 'PlayBack Location' },
        { value: 'device', label: 'Device' },
        { value: 'os', label: 'OS' }
    ]

    const videoActivityTrendDropdownOptions = [
        { value: '3s', label: '3 Sec' },
        { value: '10s', label: '10 Sec' },
        { value: '30s', label: '30 Sec' },
        { value: 'complete', label: 'Full Video' },
        { value: 'soundOnViews', label: 'Sound On Views' },
        { value: 'clickToPlay  ', label: 'Click To Play  ' },
        { value: 'autoplayedViews', label: 'Autoplayed Views' }
    ];

    const audienceGeographySourceDropdownOptions = [
        { value: 'country', label: 'Country' },
        { value: 'continent', label: 'Continent' },
        { value: 'sub_continent', label: 'Sub-Continent' },
        { value: 'provience', label: 'Provience' }
    ]

    const audienceGeographyDropdownOptions = [
        { value: 'no_of_views', label: 'No of views' },
        { value: 'avg_view_duration', label: 'Avg View Duration' },
        { value: 'viewer%', label: 'Viewer %' },
        { value: 'yt_premium_Views', label: 'YT Premium Views' }
    ]

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            0: {
                                slidesPerView: 'auto'
                            },
                            426: {
                                slidesPerView: 2
                            },
                            768: {
                                slidesPerView: 3
                            },
                            1024: {
                                slidesPerView: 3
                            },
                            1600: {
                                slidesPerView: 4
                            }
                        }}>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Videos</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Video Views (in minutes)</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Subscribers</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Views</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Views From YT Premium </h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>View Through Rate</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Subscriber Gained</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Subscriber Lost</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide role="button" >
                            <div className="card card_padded cursor-pointer">
                                <div className="statbox">
                                    <h4>Total Shares</h4>
                                    <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                    <div className="statbox_info show">
                                        <span className="green_text block">25%</span>
                                        <span className="grey_text block">Since Last Month </span>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        {!hideForKmmrce ? '' :
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Total Comments</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>}

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Engagement Rate</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Avg View Duration</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Viewer Percentage (Logged In)</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Average View Percentage</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                        {!hideForKmmrce ?
                            <SwiperSlide role="button" >
                                <div className="card card_padded cursor-pointer">
                                    <div className="statbox">
                                        <h4>Returning views</h4>
                                        <h3><span className="animate_number_decimal"><NumberCounter Number={4800} /></span>K</h3>
                                        <div className="statbox_info show">
                                            <span className="green_text block">25%</span>
                                            <span className="grey_text block">Since Last Month </span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> : ''}

                    </Swiper>
                </div>

                {!hideForKmmrce ?
                    <div className='grid grid-cols-4 grid-margin-bottom'>
                        <div className='grid col-span-2'>
                            <div className="grid card">
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Content Analysis</h3>
                                </div>
                                <div className="grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                        <StackedColumnsChart ChartId={"contentAnalysis"} ChartData={contentAnalysisData} ChartCategories={contentAnalysisDataCategory} Legend={true} Stroke={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid col-span-2'>
                            <div className="grid card">
                                <div className="table-header icz_resp_flex_column">
                                    <h3>View Trend Analysis</h3>
                                    <SelectDropdown ismulti={false} placeholder={'Select'} options={viewTrendAnalysisDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                                </div>
                                <div className="grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                        <LineChart ChartId={"viewTrendAnalysis"} ChartType={"area"} ChartData={viewTrendAnalysisData} Legend={true} Stroke={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}

                {!hideForKmmrce ?
                    <div className='grid grid-cols-1 grid-margin-bottom'>
                        <div className="card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Video Activity Trend</h3>
                                <SelectDropdown ismulti={false} placeholder={'Select Mentions'} options={videoActivityTrendDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                            </div>
                            <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                                <div className="col-span-4">
                                    <div className="line_chart_holder padded_table_sec">
                                        <div id="line-chart"></div>
                                        <HeatMapChart ChartId={"audienceActivityTrendChart"} ChartData={videoActivityTrendData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}

                {!hideForKmmrce ?
                    <div className='grid grid-cols-1 grid-margin-bottom'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Traffic Analysis</h3>
                                <div className='grid  grid-cols-2'>
                                    <SelectDropdown ismulti={false} placeholder={'Select'} options={trafficAnalysisSourceDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                                    <SelectDropdown ismulti={false} placeholder={'Select'} options={trafficAnalysisDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                                </div>
                            </div>
                            <div className="grid-cols-1 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <TreemapChart ChartId={"trafficAnalysis"} ChartData={trafficAnalysisData} />
                                </div>
                            </div>
                        </div>
                    </div> : ''}

                {!hideForKmmrce ?
                    <div className='card grid grid-margin-bottom'>
                        <div className="grid grid-cols-1">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Video Sentiment</h3>
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className='col-span-1'>
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Reaction Sentiment</h3>
                                </div>
                                <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="col-span-3">
                                        <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                            <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={videoPositiveSentimentData} Labels={videoPositiveSentimentDataLabels} Legend={true} />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="table-content">
                                            <table className="table table-striped table-bordered table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-10">Like</td>
                                                        <td className="col-md-2 align_right value_td">1.6M</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Dislike</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10"><em>Total Reactions</em></td>
                                                        <td className="col-md-2 align_right value_td"><em>800K</em></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-1'>
                                <div className="table-header">
                                    <h3>Comment Sentiment</h3>
                                </div>
                                <div className="grid grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="col-span-3">
                                        <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                            <DonutPieChart ChartId={"leads"} ChartType={"donut"} ChartData={videoCommentsSentimentData} Labels={videoCommentsSentimentDataLabels} Legend={true} />
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="table-content">
                                            <table className="table table-striped table-bordered table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-10">Positive</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Negative</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10">Neutral</td>
                                                        <td className="col-md-2 align_right value_td">800K</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-10"><em>Total Comments</em></td>
                                                        <td className="col-md-2 align_right value_td"><em>800K</em></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""}

                {!hideForKmmrce ?
                    <div className='card grid grid-margin-bottom'>
                        <div className="grid grid-cols-1 ">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Audience Demographics</h3>
                            </div>
                        </div>
                        <div className="grid grid-cols-4 ">
                            <div className='col-span-2'>
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Male</h3>
                                </div>
                                <div className="grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                        <BarChart ChartId={"socialAudienceMale"} ChartData={audienceChartData} ChartCategory={audienceCategory} isHorizontal={true} Legend={true} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-2'>
                                <div className="table-header icz_resp_flex_column">
                                    <h3>Female</h3>
                                </div>
                                <div className="grid-cols-4 table-content icz_resp_flex_column">
                                    <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                        <BarChart ChartId={"socialAudienceFemale"} ChartData={audienceChartData} ChartCategory={audienceCategory} isHorizontal={true} Legend={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}

                {!hideForKmmrce ?
                    <div className='card grid grid-margin-bottom'>
                        <div className="grid grid-cols-1">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Audience Geography</h3>
                                <div className='grid  grid-cols-2'>
                                    <SelectDropdown ismulti={false} placeholder={'Select'} options={audienceGeographySourceDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                                    <SelectDropdown ismulti={false} placeholder={'Select'} options={audienceGeographyDropdownOptions} className="icz_selectDropdown icz_sectionDropdown" />
                                </div>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCountry"} ChartData={audienceCountryData} ChartCategory={audienceCountryCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div> : ''}
                {!hideForKmmrce ? '' :
                <div className='card grid grid-margin-bottom'>
                    <div className='grid col-span-2'>
                        <div className="grid card">
                            <div className="table-header icz_resp_flex_column">
                                <h3>Subscribers Gain & Lost</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <LineChart ChartId={"viewTrendAnalysis"} ChartType={"area"} ChartData={subscribersData} Legend={true} Stroke={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="grid grid-cols-1 grid-margin-bottom">
                    <div className="card flex justify">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Top Performing Videos</h3>
                        </div>
                        <div className="grid grid-cols-1 table-content">
                            <div className="line_chart_holder padded_table_sec">
                                <DataTable isTitleEnabled={false} isSearchEnabled={false} isFooterEnabled={true} Headers={!hideForKmmrce ? topPeformingVideosTableDataHeaders : topPeformingVideosTableDataHeadersKMMRCE} TableSize={5} TableData={!hideForKmmrce ? topPeformingVideosTableData : topPeformingVideosTableDataKMMRCE} />
                            </div>
                        </div>
                    </div>
                </div>

                <ProductComparison products={products} Title={'Top 5 Videos'} />

            </div>
        </Wrapper >
    );
}
