import { useState } from 'react';
import RouteUrls from './app/lib/routeUrls'

import Topbar from './app/components/common/topbar/topbar'
import {SideBar} from './app/components/common/sidebar/sidebar'

import './App.scss';


function App() {

  const handleSidebarToggle = () => {
    document.body.classList.toggle('open_menu');
  };

  return (
    <div className="App">
      <div className="dashboard_container">
        <Topbar />
        <SideBar />
        <div className="burger_menu" onClick={handleSidebarToggle}></div>
        <RouteUrls />
      </div>
    </div>
  );
}

export default App;
