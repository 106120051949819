import React, { useState } from 'react';
import Wrapper from '../../../lib/wrapper';

import VideoFacebookPageAnalyticsOverall from './videoFacebookPageAnalyticsOverall';
import VideoFacebookPageAnalyticsOrganic from './videoFacebookPageAnalyticsOrganic';
import VideoFacebookPageAnalyticsPaid from './videoFacebookPageAnalyticsPaid';

import '../../../../App.scss';


export default function VideoFacebookPageAnalytics() {

    const [postsubnav, setPostsubnav] = useState("Overall");

    const subnav = ["Overall", "Organic", "Paid"];

    const clickHandler = (navtab) =>{
        setPostsubnav(navtab)
    }

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className='grid grid-margin-bottom'>
                    <div className="table-header">
                        <div className="group">
                            {subnav.map(navtab => (
                                <button
                                    type="button"
                                    key={navtab}
                                    className={postsubnav === navtab ? "btn btn-tertiary active" : "btn btn-tertiary"}
                                    onClick={() => clickHandler(navtab)}>
                                    {navtab}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <Wrapper >
                    {postsubnav === "Overall" && (
                        <VideoFacebookPageAnalyticsOverall />
                    )}
                    {postsubnav === "Organic" && (
                        <VideoFacebookPageAnalyticsOrganic />
                    )}
                    {postsubnav === "Paid" && (
                        <VideoFacebookPageAnalyticsPaid />
                    )}
                </Wrapper >
            </div>
        </Wrapper >
    );
}
