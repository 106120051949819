import React from 'react';

import Wrapper from '../../../lib/wrapper';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import '../../../../App.scss';


export default function CompareTwitterLikes() {

    const tabContent = [
        {
            CompetitorName: "Competitor 1",
            TotalShares: 300,
            TotalImages: 5,
        },
        {
            CompetitorName: "Competitor 2",
            TotalShares: 300,
            TotalImages: 5,
        },
        {
            CompetitorName: "Competitor 3",
            TotalShares: 300,
            TotalImages: 5,
        },
        {
            CompetitorName: "Competitor 4",
            TotalShares: 300,
            TotalImages: 5,
        },
        {
            CompetitorName: "Competitor 5",
            TotalShares: 300,
            TotalImages: 5,
        }
    ];

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className="grid grid-margin-bottom">
                    <div className='grid grid-margin-bottom'>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}
                            slidesPerView={4}
                            pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 'auto'
                                },
                                576: {
                                    slidesPerView: 'auto'
                                },
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                },
                                1200: {
                                    slidesPerView: 3
                                },
                                1600: {
                                    slidesPerView: 4
                                }
                            }}>
                            {tabContent.map((table, idx) => (
                                <SwiperSlide key={idx}>
                                    <div key={{ idx }} className="card">
                                        <div className="table-header flex justify">
                                            <h3>{table.CompetitorName}</h3>
                                        </div>
                                        <div className="table-content">
                                            <table className="table table-bordered table-hover">
                                                <tbody>
                                                    <tr>
                                                        <td>Total Likes</td>
                                                        <td className="align_right value_td">{table.TotalShares}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Tweets</td>
                                                        <td className="align_right value_td">{table.TotalImages}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
