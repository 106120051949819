import React, { useState } from 'react';

import SocialFacebookAudience from "./socialFacebookAudience";
import SocialFacebookPage from "./socialFacebookPage";
import SocialFacebookPost from "./socialFacebookPost";

import Wrapper from '../../../lib/wrapper';

import '../../../../App.scss';


const SocialFacebook = () => {

  const [activeTab, setActiveTab] = useState("Page");

  const subnav = ["Page", "Audience", "Post"];

  const clickHandler = (navtab) => {
    setActiveTab(navtab)
  }

  return (
    <Wrapper>
      <ul className="tab_navigation">
        {subnav.map((navtab, index) => {
          return (
            <li key={index} className="tab_navigation_item grid grid-margin-bottom">
              <a
                className={`tab_navigation_link ${activeTab === navtab ? "active" : ""}`}
                href={`#${navtab}`} onClick={() => clickHandler(navtab)}>
                {navtab}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab_content">
        <Wrapper >
          {activeTab === "Page" && (
            <SocialFacebookPage />
          )}
          {activeTab === "Audience" && (
            <SocialFacebookAudience />
          )}
          {activeTab === "Post" && (
            <SocialFacebookPost />
          )}
        </Wrapper >
      </div>
    </Wrapper >
  );
}

export default SocialFacebook;
