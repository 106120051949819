import React from 'react';

import Wrapper from '../../../lib/wrapper';

import BarChart from '../../../components/common/charts/barChart';
import HeatMapChart from '../../../components/common/charts/heatMapChart';

import '../../../../App.scss';

export default function SocialInstagramAudience() {

    const instaAudienceChartData = [40, 30, 40, 45, 50];
    const instaAudienceCategory = ["13-17", "18-24", "25-36", "37-50", "50+"]

    const instaAudienceCountryData = [7993, 9642, 5521, 9252, 2023, 2175, 5105, 4517, 5552, 5104];
    const instaAudienceCountryCategory = ["India", "UAE", "Japan", "Germany", "Australia", "Switzerland ", "Canada", "United Kingdom", "United States", "Sweden"]
    const instaAudienceCityCategory = ["Tokyo", "New York", "Mumbai", "Istanbul", "Chicago", "London ", "Paris", "Dubai", "Abu Dhabi", "Al Sharjah"]

    const instaAudienceActivityTrendData = [{ "name": "Mon", "data": [73, 68, 10, 24, 43, 59, 75, 65, 11, 76, 27, 56, 40, 84, 81, 86, 9, 35, 31, 7, 62, 38, 59, 73] }, { "name": "Tue", "data": [11, 4, 61, 87, 81, 40, 44, 76, 74, 53, 77, 68, 19, 30, 87, 54, 59, 88, 16, 47, 11, 77, 40, 14,] }, { "name": "Wed", "data": [59, 4, 21, 7, 3, 43, 59, 67, 51, 33, 48, 82, 21, 84, 2, 75, 44, 80, 67, 24, 48, 44, 4, 6,] }, { "name": "Thu", "data": [31, 81, 56, 46, 46, 13, 35, 16, 84, 83, 54, 14, 33, 33, 73, 82, 53, 23, 27, 78, 84, 27, 47, 76] }, { "name": "Fri", "data": [47, 53, 71, 50, 85, 15, 86, 56, 70, 40, 18, 90, 76, 49, 69, 70, 46, 42, 66, 50, 32, 29, 21, 43] }, { "name": "Sat", "data": [10, 59, 89, 46, 11, 57, 4, 35, 26, 56, 21, 52, 44, 46, 26, 77, 66, 70, 73, 63, 69, 44, 84, 24] }, { "name": "Sun", "data": [64, 65, 71, 8, 10, 41, 44, 21, 77, 12, 38, 7, 64, 65, 71, 8, 10, 41, 44, 21, 10, 41, 44, 21] }];

    return (
        <Wrapper>
            <div className="dashboard_home">
                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Male</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"socialAudienceMale"} ChartData={instaAudienceChartData} ChartCategory={instaAudienceCategory} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>Female</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"socialAudienceFemale"} ChartData={instaAudienceChartData} ChartCategory={instaAudienceCategory} isHorizontal={true} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card grid grid-margin-bottom'>
                    <div className="grid grid-cols-1 ">
                        <div className="table-header icz_resp_flex_column">
                            <h3>Audience Distribution</h3>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 ">
                        <div className='col-span-2'>
                            <div className="table-header icz_resp_flex_column">
                                <h3>By Country</h3>
                            </div>
                            <div className="grid-cols-4 table-content icz_resp_flex_column">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                    <BarChart ChartId={"fbAudienceDistributionCountry"} ChartData={instaAudienceCountryData} ChartCategory={instaAudienceCountryCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <div className="table-header">
                                <h3>By City</h3>
                            </div>
                            <div className="grid-cols-4 table-content">
                                <div className="line_chart_holder radial_chart_holder padded_table_sec">
                                <BarChart ChartId={"fbAudienceDistributionCity"} ChartData={instaAudienceCountryData} ChartCategory={instaAudienceCityCategory} isHorizontal={false} Legend={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card fullpage_card">
                    <div className="table-header flex justify icz_resp_flex_column">
                        <h3>Audience Activity Trend</h3>
                    </div>
                    <div className="table-content">
                        <div className='line_chart_holder radial_chart_holder padded_table_sec'>
                            <HeatMapChart ChartId={"audienceActivityTrendChart"} ChartData={instaAudienceActivityTrendData} />
                        </div>
                    </div>
                </div>

            </div>
        </Wrapper >
    );
}
