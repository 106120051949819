import { useEffect, useState } from 'react';

import '../../../../App.scss';

export default function Topbar() {

    const [scrolled, setScrolled] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState("false");

    const handleToggle = () => {
        setDropdownOpen(!isDropdownOpen);
      };

    useEffect(() => {
        window.addEventListener("scroll", () => {

            setScrolled(window.scrollY > 80);
        });

    }, [setScrolled]);

    const handleSidebarToggle = () => {
        document.body.classList.toggle('open_menu');
      };

    return (
        <nav className="dashboard_navbar">
            <div className="burger_menu_overlay" onClick={handleSidebarToggle}></div>
            <a className="kmmrce_logo" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.31" height="34.191" viewBox="0 0 34.31 34.191">
                    <path id="Union_2" data-name="Union 2"
                        d="M1589,34.191V0h10.542V34.191Zm21.147-6.492a6.593,6.593,0,0,1,13.162,0,6.593,6.593,0,0,1-13.162,0Zm-9.394-10.619a6.411,6.411,0,1,1,6.411,6.411A6.411,6.411,0,0,1,1600.754,17.08Zm9.616-10.669a6.411,6.411,0,1,1,6.411,6.411A6.411,6.411,0,0,1,1610.369,6.411Z"
                        transform="translate(-1589)" fill="#333"></path>
                </svg>
            </a>
            <div className={`flex justify ${scrolled ? 'scrollUp' : ''}`} id="top_nav_bar">
                <div className="left">
                    <h1 className="site_name name_tag">
                        <figure style={{ "background": "#578626" }}>T</figure>
                        test1
                    </h1>
                </div>
                <div className="right flex center">
                    <div className="top_nav_btn hide_mobile">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.219" height="16.337"
                            viewBox="0 0 18.219 16.337">
                            <path id="Path_25626" data-name="Path 25626"
                                d="M27.54,15.78H14a2.241,2.241,0,0,0-2.238,2.238v8.131A2.241,2.241,0,0,0,14,28.387h3.958l5.209,3.466a.353.353,0,0,0,.549-.294V28.387H27.54a2.241,2.241,0,0,0,2.238-2.238V18.018A2.241,2.241,0,0,0,27.54,15.78Zm1.531,10.366a1.534,1.534,0,0,1-1.531,1.531h-4.18a.353.353,0,0,0-.353.353v2.86L18.26,27.739a.353.353,0,0,0-.2-.059H14a1.534,1.534,0,0,1-1.531-1.531V18.018A1.534,1.534,0,0,1,14,16.487H27.54a1.534,1.534,0,0,1,1.531,1.531Z"
                                transform="translate(-11.66 -15.68)" fill="#8e8e8e" stroke="#8e8e8e"
                                strokeWidth="0.2" />
                        </svg>

                    </div>
                    <div className="top_nav_btn has_notification">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.313" height="17.026"
                            viewBox="0 0 16.313 17.026">
                            <g id="NOTIFICATION" transform="translate(-96.841 -171.144)">
                                <path id="Path_25628" data-name="Path 25628"
                                    d="M242.447,183.662h-2.1v-6.651a5.562,5.562,0,0,0-1.7-4.41,5.775,5.775,0,0,0-3.868-1.3,5.977,5.977,0,0,0-3.868,1.3,5.6,5.6,0,0,0-1.663,4.41v6.651h-2.1a.362.362,0,1,0,0,.723h15.29a.362.362,0,0,0,0-.723Zm-12.471-6.651a4.815,4.815,0,0,1,1.446-3.868,4.884,4.884,0,0,1,2.892-1.12h.976a4.884,4.884,0,0,1,2.892,1.12,4.988,4.988,0,0,1,1.446,3.868v6.651h-9.651Z"
                                    transform="translate(-129.805 0)" fill="#8e8e8e" stroke="#8e8e8e"
                                    strokeWidth="0.3" />
                                <path id="Path_25629" data-name="Path 25629"
                                    d="M313.365,382.413a2.45,2.45,0,0,1-2.567-2.494.362.362,0,1,1,.723,0,1.728,1.728,0,0,0,1.843,1.771,1.7,1.7,0,0,0,1.807-1.771.362.362,0,1,1,.723,0,2.4,2.4,0,0,1-2.53,2.494Z"
                                    transform="translate(-208.385 -194.393)" fill="#8e8e8e" stroke="#8e8e8e"
                                    strokeWidth="0.3" />
                            </g>
                        </svg>
                    </div>
                    <div onClick={handleToggle} className="nav-item dropdown position-relative">
                        <div className={isDropdownOpen ? "name_tag inner-menu-toggle" : "name_tag inner-menu-toggle open"} role="button" aria-haspopup="true"
                            aria-expanded="false">
                            <figure style={{ "background": "#4B42AE" }}>M</figure>
                            <span className="hide_mobile">amit.tripathi@icogz.com</span>
                            <svg className="menu-arrow" xmlns="http://www.w3.org/2000/svg" width="12.445" height="7.4"
                                viewBox="0 0 12.445 7.4">
                                <path id="Path_25570" data-name="Path 25570"
                                    d="M23.853,17.653a.567.567,0,0,1,0,.764l-5.5,5.918a.476.476,0,0,1-.71,0l-5.5-5.918a.57.57,0,0,1,0-.764.477.477,0,0,1,.71,0L18,23.195,19.4,21.68l3.739-4.027a.476.476,0,0,1,.71,0Z"
                                    transform="translate(-11.756 -17.294)" fill="#8e8e8e" stroke="#8e8e8e"
                                    strokeWidth="0.4" />
                            </svg>
                        </div>
                        <ul className={isDropdownOpen ? "inner-menu" : "inner-menu active"}>
                            <li className="inner-menu-li">
                                <div className="inner-menu-item"> Return to site</div>
                            </li>
                            <li className="inner-menu-li">
                                <div className="inner-menu-item">Account</div>
                            </li>
                            <li className="inner-menu-li">
                                <div className="inner-menu-item"> Log out</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}
